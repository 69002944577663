// External libraries.
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// Internal components.
import { datosEvaluacionLectura } from "../../inconformidad/services/evaluacionInconformidadService";
import TablaResultadosEvaluacion from "../../evaluacion/components/TablaResultadosEvaluacion";
import { ERROR_MESSAGES_GENERICS_API } from "../../../utils/messagesFromAPI";
import { useEvaluationStore } from "../../../store/useEvaluationStore";
import Loading from "../../../reutilizable/Loading";
import ProcesarCriterios from "./ProcesarCriterios";
import Alert from "../../../reutilizable/Alert";

// Styles.
import styles from "./styles/FormEvaluacionInconformidad.module.css";

const ALIAS_ACTIVIDAD = { inconformidad: "inconformidad", evaluacion: "evaluacion" };

const FormEvaluacionInconformidad = () => {
  const { idSolicitud } = useEvaluationStore();
  const { register, watch } = useForm();

  const [resultadosEvaluacion, setResultadosEvaluacion] = useState();
  const [arbolCriterios, setArbolCriterios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [miembros, setMiembros] = useState([]);
  const [puntaje, setPuntaje] = useState({});
  const [mensaje, setMensaje] = useState({
    type: null,
    mensaje: null,
  });
  const [nivel, setNivel] = useState();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [idSolicitud]);

  const getData = async () => {
    setMensaje(null);
    const tipoParticipacion = ALIAS_ACTIVIDAD.evaluacion;

    if (idSolicitud) {
      setIsLoading(true);

      await datosEvaluacionLectura(idSolicitud, tipoParticipacion)
        .then((data) => {
          setMiembros(data.miembros);
          setArbolCriterios(data.arbolCriterios);
          setResultadosEvaluacion(data.tablaResultados);
          setPuntaje(data.puntaje);
          setNivel(data.nivelPrimerEvaluacion);
        })
        .catch((error) => {
          console.error("Error al obtener los datos de evaluación: ", error);
          if (error.response) {
            const message = ERROR_MESSAGES_GENERICS_API[error.response.status] || ERROR_MESSAGES_GENERICS_API.default;
            setMensaje({
              mensaje: message,
              type: "error",
            });
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  if (isLoading) return <Loading />;

  if (mensaje) {
    return (
      <Alert typeAlert={mensaje.type}>
        <p>{mensaje.mensaje}</p>
      </Alert>
    );
  }

  return (
    <>
      <div className={styles.formEvaluacion}>
        <ProcesarCriterios
          criterios={arbolCriterios}
          puntaje={puntaje}
          register={register}
          watch={watch}
          pdfDraw={true}
          typeRadio={0}
        />
      </div>

      <div className={styles.restForm}>
        <div className={styles.submit}>
          <TablaResultadosEvaluacion resultados={resultadosEvaluacion} nivel={nivel} />
        </div>
      </div>
    </>
  );
};

export default FormEvaluacionInconformidad;
