import { useState } from "react";
import { useEvaluationStore } from "../../../store/useEvaluationStore";
import {
  generatePDFDicNoParticipant,
  uploadDictamenNoParticipante,
} from "../services/dictamenService";
import { generatePDF } from "../utils";

export const useDictamenNoParticipantes = () => {
  const [dictamenFile, setDictamenFile] = useState(null);
  const [actaFile, setActaFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedDataAcademico } = useEvaluationStore();

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];

    if (!file) return;

    const allowedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const maxFileSize = 2 * 1024 * 1024;

    if (!allowedTypes.includes(file.type)) {
      alert("Solo se permiten archivos en formato PDF o DOCX.");
      e.target.value = "";
      return;
    }

    if (file.size > maxFileSize) {
      alert("El tamaño del archivo no debe superar los 2 MB.");
      e.target.value = "";
      return;
    }

    if (type === "dictamen") setDictamenFile(file);
    if (type === "acta") setActaFile(file);
  };

  const handleFileUpload = async (type) => {
    setIsLoading(true);
    try {
      if (type === "dictamen") {
        const body = setData(type);
        console.log(body)
        await uploadDictamenNoParticipante(body);
        alert("Archivo subido correctamente.");
        setDictamenFile(null);
        document.getElementById("dictamenInput").value = "";
      }
      if (type === "acta") {
        const data = setData(type);
        await uploadDictamenNoParticipante(data);
        alert("Archivo subido correctamente.");
        setActaFile(null);
        document.getElementById("actaInput").value = "";
      }
    } catch (error) {
      alert("Error al subir el archivo.");
    } finally {
      setIsLoading(false);
    }
  };

  const setData = (type) => {
    const formData = new FormData();
    if (type === "dictamen" && dictamenFile) {
      formData.append("archivo", dictamenFile);
      formData.append("idSolicitud", selectedDataAcademico.id);
      formData.append("dictamen", true);
    }

    if (type === "acta" && actaFile) {
      formData.append("archivo", actaFile);
      formData.append("idSolicitud", selectedDataAcademico.id);
      formData.append("dictamen", false);
    }
    return formData;
  };

  const handleGenerateDictamen = async () => {
    setIsLoading(true);
    try {
      const payload = {
        academicosSeleccionados: [selectedDataAcademico.codigo],
        tipo: "inconformidad",
      };
      const data = await generatePDFDicNoParticipant(payload);
      generatePDF(data);
      alert("Dictamen de no participante generado exitosamente.");
    } catch (error) {
      alert("Error al generar el dictamen de no participante.");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    // properties
    isLoading,
    dictamenFile,
    actaFile,
    // methods
    handleFileChange,
    handleFileUpload,
    handleGenerateDictamen,
  };
};
