
import axios from 'axios';
import { getAccessToken } from '../../../authService';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const URL_TIPOS_DOCUMENTOS = `${API_BASE_URL}/api/v1/expediente/obtener-etapa-documentos`;
const URL_SOLICITUD = ``;

export const tiposDocumentos = async () => {
  try {
    const token = await getAccessToken();
    const response = await axios(URL_TIPOS_DOCUMENTOS, {
      headers: {
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting datos de evaluacion: ", error);
    throw error;
  }
};

export const consultarSolicitud = async () => {
  try {
    const token = await getAccessToken();
    const response = await axios.get(`${URL_SOLICITUD}`, {
      headers: {
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting datos de evaluacion: ", error);
    throw error;
  }
};

