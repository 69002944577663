import React from "react";
import { Menu, useMenu } from "../../../../reutilizable/Menu";
import { Footer, Header } from "../../../layout";
import { BusquedaAvanzada } from "../../components/BusquedaAvanzada";
import CotejoPage from "../../pages/CotejoPage";
import GenerarDocInconformidad from "../../secretariaAdminSems/GenerarDocInconformidad/page/DocInconformidadPage";
import GenerarReporte from "../../secretariaAdminSems/GenerarReportes/page/GenerarReportePage";

import useMenuData from '../../../../hooks/useMenuData';
import Loading from '../../../../reutilizable/Loading';
import Alert from '../../../../reutilizable/Alert';
import styles from "./styles/SecretariaPreparatoriaPage.module.css";
import VerExpedientePage from '../../pages/verExpediente/VerExpedientePage';
import SubirDocumentosPage from "../../components/subir-documentos/page/SubirDocumentosPage";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_URL_SUBMENUS = `${API_BASE_URL}/api/v1/actividad/secretaria_escuelas_preparatorias`;

export const SecretariaPreparatoriaPage = () => {

  const componentMap = {
    "Inconformidad": <GenerarDocInconformidad />,
    "Evaluación": <BusquedaAvanzada />,
    "Subir documentos": <SubirDocumentosPage />,
    "Solicitudes": <CotejoPage />,
    "Generar reportes": <GenerarReporte />,
    "Ver Expediente": <VerExpedientePage />
 
  }
  
  const { menu: menuData, menuConfig, error, isLoading } = useMenuData({
    apiUrl: API_URL_SUBMENUS,
    componentMap: componentMap,
    defaultSelectedValue: 'Instrucciones',
    isVertical: false
  });

  const menu = useMenu(menuData, menuConfig);

  return (
    <div className={styles.jefe_departamento_page_container}>
      <Header />
      <Menu menu={menu} />
      {isLoading && (<Loading />)}
      { error && (
        <Alert typeAlert={error.type}>
          <p>{error.message}</p>
        </Alert>
      )}
      {menu.element}
      <Footer />
    </div>
  );
};

