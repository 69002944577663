import { useEffect, useState } from "react";
import {
  getCeroPlanTrabajoAdapter,
  getPlanTrabajoAdapter,
} from "../adapters/PlanTrabajo.adapter";
import {
  consultaPlanTrabajoCero,
  getPlanTrabajo,
} from "../services/consultaPlanTrabajo";

const initialDatosAcademico = {
  schoolCycle: "",
  code: "",
  teaching: "",
  status: "",
  generation: "",
  academicManagement: "",
  id: "",
  teacherName: "",
  observations: "",
  requestID: "",
  linkage: "",
};

const usePlanTrabajo = () => {
  const [datosAcademico, setDatosAcademico] = useState(initialDatosAcademico);
  const [initialError, setInitialError] = useState(false);

  const obtainCeroPlanTrabajo = async () => {
    try {
      const data = await consultaPlanTrabajoCero();
      const dataAdapter = getCeroPlanTrabajoAdapter(data);

      setDatosAcademico({
        ...datosAcademico,
        ...dataAdapter,
      });
    } catch (error) {
      setInitialError(true);
      console.log(error.message);
    }
  };

  const obtainPlanTrabajo = async () => {
    try {
      const res = await getPlanTrabajo();
      const dataAdapter = getPlanTrabajoAdapter(res);

      setDatosAcademico({
        ...datosAcademico,
        ...dataAdapter,
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await obtainPlanTrabajo();
      } catch (error) {
        console.log("Error se hará un fullback de obtainCeroPlanTrabajo");
        obtainCeroPlanTrabajo();
      }
    })();
    //  eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDatosAcademico({
      ...datosAcademico,
      [name]: value,
    });
  };

  return {
    // properties
    datosAcademico,
    initialError,
    // methods
    handleChange,
    obtainPlanTrabajo,
  };
};

export default usePlanTrabajo;
