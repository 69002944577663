import api from "../../../../hooks/api";

const API_URL_SUPERPASE = `/api/v1/superpase`;

export const getDataAcademic = async (code) => {
  try {
    const response = await api.get(`${API_URL_SUPERPASE}/${code}`);

    if (response.status !== 200) throw new Error("No se encontró el Academico");
    if (Object.keys(response.data).length <= 0)
      throw new Error("No se encontró información el Academico");

    return response.data;
  } catch (error) {
    throw new Error("Error al obtener los datos del académico");
  }
};

export const updateSuperPase = async (code, superPase) => {
  try {
    const response = await api.put(`${API_URL_SUPERPASE}/${code}`, {
      activar: superPase,
    });

    if (response.status !== 200)
      throw new Error("Error al actualizar el superpase");

    return response.data;
  } catch (error) {
    throw new Error("Error al actualizar el superpase");
  }
};
