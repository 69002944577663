import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import TablaResultadosEvaluacion from "../../evaluacion/components/TablaResultadosEvaluacion";
import Loading from "../../../reutilizable/Loading";
import { useEvaluationStore } from "../../../store/useEvaluationStore";
import { datosEvaluacionInconformidad, evaluarInconformidad } from "../services/evaluacionInconformidadService";
import arbolCriteriosJson from "../services/arbolCriterios.json";
import { ERROR_MESSAGES_GENERICS_API } from "../../../utils/messagesFromAPI";
import Alert from "../../../reutilizable/Alert";
import Criterios from "./Criterios";
import styles from "./styles/FormEvaluacionInconformidad.module.css";
import { useInconformidadStore } from "../../../store/useInconformidadStore";

const ALIAS_ACTIVIDAD = {
  inconformidad: "inconformidad",
  evaluacion: "evaluacion",
};

const FormEvaluacionInconformidad = () => {
  const { idSolicitud } = useEvaluationStore();
  const { setUltimoMiembro } = useInconformidadStore();
  const { handleSubmit, register, watch, setValue } = useForm();
  const [resultadosEvaluacion, setResultadosEvaluacion] = useState();
  const [nivel, setNivel] = useState();
  const [arbolCriterios, setArbolCriterios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [miembros, setMiembros] = useState([]);
  const [puntaje, setPuntaje] = useState({});
  const [mensaje, setMensaje] = useState({
    type: null,
    mensaje: null,
  });
  const [codigoUsuario, setCodigoUsuario] = useState(null);

  const username = localStorage.getItem("userName" || "");

  const [selectedValue, setSelectedValue] = useState();
  const [respuestaAcademico, setRespuestaAcademico] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (username) setCodigoUsuario(username);
  }, [username]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [idSolicitud]);

  const getData = async () => {
    setMensaje(null);
    const tipoParticipacion = ALIAS_ACTIVIDAD.inconformidad;

    if (idSolicitud) {
      setIsLoading(true);

      await datosEvaluacionInconformidad(idSolicitud, tipoParticipacion)
        .then((data) => {
          setMiembros(data.miembros);
          setArbolCriterios(data.arbolCriterios);
          setResultadosEvaluacion(data.tablaResultados);
          setPuntaje(data.puntaje);
          setNivel(data.nivelPrimerEvaluacion)
          setSelectedValue(data.ratificaModifica);
          setRespuestaAcademico(data.respuestaInconformidad);
          setUltimoMiembro(data.ultimoMiembro.nombre);
        })
        .catch((error) => {
          console.error("Error al obtener los datos de evaluación: ", error);
          if (error.response) {
            const message = ERROR_MESSAGES_GENERICS_API[error.response.status] || ERROR_MESSAGES_GENERICS_API.default;
            setMensaje({
              mensaje: message,
              type: "error",
            });
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    setArbolCriterios(arbolCriteriosJson.arbolCriterios);
  }, []);

  useEffect(() => {
    setMiembros(arbolCriteriosJson.miembros);
  }, []);

  const handleSubmitEvaluacion = async (data) => {
    const aliasActividad = ALIAS_ACTIVIDAD.inconformidad;
    const { idMiembro, ...rest } = data;
    const idMiembroToNumber = Number(idMiembro);

    const valoresPuntajeData = rest;

    const valoresPuntajeFormat = Object.keys(valoresPuntajeData).reduce((acc, key) => {
      acc[key] =
        valoresPuntajeData[key] === undefined || valoresPuntajeData[key] === null ? "0" : valoresPuntajeData[key];
      return acc;
    }, {});

    const body = {
      idSolicitud,
      idMiembro: idMiembroToNumber,
      aliasActividad,
      valoresPuntaje: valoresPuntajeFormat,
      ratificaModifica: selectedValue,
      respuestaInconformidad: respuestaAcademico,
    };

    await evaluarInconformidad(body)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error("Error al evaluar la inconformidad: ", error);
        if (error.response) {
          const message = ERROR_MESSAGES_GENERICS_API[error.response.status] || ERROR_MESSAGES_GENERICS_API.default;
          setMensaje({
            mensaje: message,
            type: "error",
          });
        }
      })
      .finally(() => {
        getData();
      });
  };

  const currentUser = miembros?.find((miembro) => miembro.codigo.toString() === codigoUsuario);
  useEffect(() => {
    if (codigoUsuario) {
      setValue("idMiembro", currentUser?.id);
    }
  }, [currentUser, setValue, codigoUsuario]);
  if (isLoading) return <Loading />;

  if (mensaje) {
    return (
      <Alert typeAlert={mensaje.type}>
        <p>{mensaje.mensaje}</p>
      </Alert>
    );
  }

  console.log(currentUser);

  //if (!arbolCriterios || arbolCriterios.length === 0) return null;

  return (
    <form onSubmit={handleSubmit(handleSubmitEvaluacion)}>
      <div className={styles.formEvaluacion}>
        <Criterios
          criterios={arbolCriterios}
          puntaje={puntaje}
          register={register}
          watch={watch}
          setValue={setValue}
          pdfDraw={true}
          typeRadio={0}
        />
      </div>

      {/* Sección del resto del formulario */}
      <div className={styles.restForm}>
        <div className={styles.containerInputsSelectRadio}>
          <div className={styles.inputSelect}>
          <select
            disabled
            {...register("idMiembro")}
            value={watch("idMiembro") || ""}
            onChange={(e) => setValue("idMiembro", e.target.value)}
          >
  <option value="" disabled>
    -- Seleccione el nombre de la lista --
  </option>
  {miembros.map((miembro) => (
    <option key={miembro.codigo} value={miembro.id}>
      {miembro.nombre}
    </option>
  ))}
</select>

          </div>
          <div className={styles.inputRadio}>
            <div>
              <input
                type="radio"
                name="radio"
                value={0}
                onChange={handleChange}
                defaultChecked={!selectedValue ? true : false}
              />
              <label htmlFor="ratifica">Ratifica</label>
            </div>
            <div>
              <input
                type="radio"
                name="radio"
                value={1}
                onChange={handleChange}
                defaultChecked={selectedValue ? true : false}
              />
              <label htmlFor="modifica">Modifica</label>
            </div>
          </div>
        </div>

        <div className={styles.textarea}>
          <label htmlFor="respuestaAcademico" style={{ marginBottom: 20 }}>
            Escriba la respuesta del académico
          </label>
          <textarea name="respuestaAcademico" value={respuestaAcademico} onChange={(e) => setRespuestaAcademico(e.target.value)}></textarea>
        </div>

        <div className={styles.submit}>
          <input type="submit" value="Evaluar" />
          <TablaResultadosEvaluacion
            resultados={resultadosEvaluacion}
            nivel={nivel}
          />
        </div>
      </div>
    </form>
  );
};

export default FormEvaluacionInconformidad;
