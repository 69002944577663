import React, { useEffect, useState } from "react";
import styles from "./styles/DicFinal.module.css";
import { generatePDFDicFinal, uploadDictamenFinal } from "../services/dictamenService";
import { useEvaluationStore } from '../../../store/useEvaluationStore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Modal from "../../../reutilizable/Modal";
import ViewerPDF from "../../../reutilizable/ViewerPDF";
import { useDacFinal } from "../hooks/useDacFinal";

const DicFinal = () => {
    const {
        closeModal,
        handleFileChange,
        handleFileUpload,
        handleGenerateDictamen,
        handleSetUrlPdf,
        openModal,
        actaFile,
        actaNodoId,
        dictamenFile,
        isActaUploaded,
        isDictamenUploaded,
        isLoadingDictamen,
        isLoadingActa,
        isLoading,
        isModalOpen,
        urlPdf
    } = useDacFinal();


    return (
        <div className={styles.container}>
            <Modal isOpen={isModalOpen} onClose={closeModal} width='850px'>
                <ViewerPDF urlPdf={urlPdf} />
            </Modal>

            <button
                className={styles.generateButton}
                onClick={handleGenerateDictamen}
                disabled={isLoading}
            >
                GENERAR DICTAMEN FINAL
            </button>

            <div className={styles.uploadSection}>
                <h2>Subir documentos</h2>

                <div className={styles.uploadRow}>
                    <label className={styles.label}>Dictamen</label>
                    {isDictamenUploaded ? (
                        <>
                            <button
                                type='button'
                                className={styles.buttonPdf}
                                title='Ver PDF'
                                onClick={() => handleSetUrlPdf("dictamen")}
                            >
                                <FontAwesomeIcon icon={faFilePdf} color='green' size='2xl' />
                            </button>
                            <p className={styles.uploadedMessage}>✅ Dictamen ya subido</p>
                        </>
                    ) : (
                        <>
                            <input
                                id="dictamenInput"
                                type="file"
                                className={styles.fileInput}
                                onChange={(e) => handleFileChange(e, "dictamen")}
                                accept=".pdf,.docx"
                                disabled={isLoadingDictamen}
                            />
                            <button
                                className={styles.uploadButton}
                                onClick={() => handleFileUpload("dictamen")}
                                disabled={!dictamenFile || isLoadingDictamen}
                            >
                                {isLoadingDictamen ? "Subiendo..." : "Subir"}
                            </button>
                        </>
                    )}
                </div>

                <div className={styles.uploadRow}>
                    <label className={styles.label}>Acta firmada</label>
                    {isActaUploaded ? (
                        <>
                            <button
                                type='button'
                                className={styles.buttonPdf}
                                title='Ver PDF'
                                onClick={() => handleSetUrlPdf("acta")}
                            >
                                <FontAwesomeIcon icon={faFilePdf} color='green' size='2xl' />
                            </button>
                            <p className={styles.uploadedMessage}>✅ Acta firmada ya subida</p>
                        </>
                    ) : (
                        <>
                            <input
                                id="actaInput"
                                type="file"
                                className={styles.fileInput}
                                onChange={(e) => handleFileChange(e, "acta")}
                                accept=".pdf,.docx"
                                disabled={isLoadingActa}
                            />
                            <button
                                className={styles.uploadButton}
                                onClick={() => handleFileUpload("acta")}
                                disabled={!actaFile || isLoadingActa}
                            >
                                  {isLoadingActa ? "Subiendo..." : "Subir"}

                            </button>
                        </>
                    )}
                </div>

            </div>
        </div>
    );
};

export default DicFinal;
