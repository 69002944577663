import { faAngleRight, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Loading from "../../../reutilizable/Loading";
import SearchResultadoEvaluacion from "../../../reutilizable/SearchResultadoEvaluacion";
import Table from "../../../reutilizable/Table";
import { useResultadoEvaluacionStore } from "../../../store/useResultadoEvaluacionStore";
import AlertaDescargandoDoc from "../../secretaria/components/AlertaDescargandoDoc";
import SelectResultadoEvaluacion from "../components/SelectResultadoEvaluacion";
import { CABECERAS_TABLA } from '../data/index';
import styles from "./styles/ReporteResultadosEvaluacion.module.css";
import Alert from "../../../reutilizable/Alert";

const ReporteResultadosEvaluacion = () => {
  const {
    fetchResultadoEvaluacion,
    resultadoEvaluacionList,
    isErrorResultadoEvaluacionList,
    isLoading,
    generatePdf,
  } = useResultadoEvaluacionStore();

  const [mensajeDescarga, setMensajeDescarga] = useState(null);

  useEffect(() => {
    fetchResultadoEvaluacion();
    // eslint-disable-next-line
  }, []);

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        // Si el ID ya está seleccionado, lo eliminamos
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        // Si el ID no está seleccionado, lo agregamos
        return [...prevSelectedRows, id];
      }
    });
  };

  const fetchSuggestions = async (query) => {
    //const suggestions = await bodyTable.filter(suggestion => suggestion.nombre === inputValue)
    const suggestions = resultadoEvaluacionList.flatMap((item) =>
      Object.values(item)
        .filter((value) =>
          value.toString().toLowerCase().includes(query.toLowerCase())
        )
        .map((value) => value.toString())
    );
    return suggestions;
  };

  const handleSubmit = async () => {
    try {
      if (selectedRows.length === 0) {
        const errorMessage = {
          type: 'warning',
          mensaje: 'No has seleccionado ninguna fila',
        };
        setMensajeDescarga(errorMessage);
        return console.log('Esta vacio');
      }
      const requestData = {
        ids: [...selectedRows].map((id) => id.toString()),
      };

      console.log("Datos a enviar:", requestData);

      // const response = await axios.post(
      //   "http://localhost:8081/api/v1/reportes/generar_reporte_evaluacion",
      //   requestData,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //       "Content-Type": "application/json",
      //     },
      //     responseType: "blob",
      //   }
      // );
      const response = await generatePdf(requestData);

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(url, "_blank");
      const succesMessage = {
        type: 'success',
        mensaje: 'Se genero los reportes correctamente',
      };
      setMensajeDescarga(succesMessage);
      setTimeout(() => {
        setMensajeDescarga(null);
      }, 5000);
    } catch (error) {
      console.log(error);
      const errorMessage = {
        type: 'error',
        mensaje: 'Ha ocurrido un error en el servidor',
      };
      setMensajeDescarga(errorMessage);
    } finally {
      setTimeout(() => {
        setMensajeDescarga(null);
      }, 3000);
    }
  };

  if (isLoading) return <Loading />;
  return (
    <div className={styles.container}>
      {isErrorResultadoEvaluacionList && (
        <Alert typeAlert="error" />
      )}
      <div>
        <h2 className={styles.titlePage}>TABLA DE RESULTADOS DE EVALUACIÓN</h2>
      </div>

      <div className={styles.containerParrafo}>
        <p className={styles.parrafoInstruccionesConIcon}>
          <FontAwesomeIcon icon={faAngleRight} color={"yellow"} /> Instrucciones
        </p>

        <p className={styles.parrafoInstrucciones}>
          Seleccione de la <span>Tabla de resultados de evaluación</span> los
          acádemicos para los cuales se vaya a generar el reporte. Elija los que
          desee <br /> usando las casillas correspondientes, o todos los de la
          tabla haciendo clic en la casilla de la cabecera. <br />
          También puede realizar búsquedas especificas por nombre, apellido,
          código, tipo de participación u otros datos del académico. <br /> Si
          se desea hacer búsquedas por nivel (I-IX), habrá que especificarlo de
          la siguiente forma: "n=I" o "n=II", etc. <br />
          Cuando haya terminado, haga clic en "Generar PDF{" "}
          <FontAwesomeIcon icon={faSave} color={"cyan"} />
          ".
        </p>
      </div>

      <div className={styles.containerTable}>
        <div className={styles.containerFiltrosTabla}>
          <div>
            <p className={styles.textoGris}>Buscar:</p>
            <SearchResultadoEvaluacion
              placeholder={"Teclee su búsqueda"}
            />{" "}
            {/* Pendiente pasar las props de este componente */}
          </div>
          <p className={styles.textoGris}>en</p>
          <div>
            <SelectResultadoEvaluacion />{" "}
            {/* Pendiente pasar las props de este componente */}
          </div>
        </div>
        <Table cabecerasTable={CABECERAS_TABLA} withHeader={true}>
          {resultadoEvaluacionList &&
            resultadoEvaluacionList.map((dataBody) => (
              <tr key={dataBody.id}>
                <td className={`${styles.tdCheckbox}`}>
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(dataBody.codigo)}
                    onChange={() => handleSelectRow(dataBody.codigo)}
                  />
                </td>
                <td className={`${styles.td} ${styles.tdTextCenter}`}>
                  {dataBody.id}
                </td>
                <td className={`${styles.td} ${styles.tdNombre}`}>
                  {dataBody.nombre} { }
                  {dataBody.apellidoPaterno} { }
                  {dataBody.apellidoMaterno} 
                </td>
                <td className={`${styles.td} ${styles.tdTextCenter}`}>
                  {dataBody.tipoParticipacion}
                </td>
                <td className={`${styles.td} ${styles.tdTextCenter}`}>
                  {dataBody.nombreDependencia}
                </td>
                <td className={`${styles.td} ${styles.tdTextCenter}`}>{dataBody.puntajesEvaluacion.nivel}</td>
                <td className={`${styles.td} ${styles.tdTextCenter}`}>
                  {dataBody.puntajesEvaluacion.calidad}
                </td>
                <td className={`${styles.td} ${styles.tdTextCenter}`}>
                  {dataBody.puntajesEvaluacion.puntajeTotal}
                </td>
              </tr>
            ))}
        </Table>
      </div>

      <div className={styles.containerButtons}>
        <button
          type="button"
          placeholder="Limpiar"
          className="texto_con_icono"
          onClick={handleSubmit}
        >
          Generar PDF <FontAwesomeIcon icon={faSave} color="cyan" />
        </button>
      </div>
      {mensajeDescarga && (
        <AlertaDescargandoDoc mensajeDescarga={mensajeDescarga} />
      )}
    </div>
  );
};

export default ReporteResultadosEvaluacion;
