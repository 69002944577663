import React, { useState } from "react";
import { replaceBrWithNewline } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import styles from "./styles/RowTableCriteriosCotejo.module.css";
import useCotejoStore from "../../../store/useCotejoStore";
import CargarEvidencia from "../../academico/expediente/components/CargarEvidencia";
import { changeCriterio } from "../services/cotejoCriteriosService";
import MessageModal from "../../../reutilizable/SucessModal";

const RowTableCriteriosCotejo = ({ item, openModal, setUrlPdf, register }) => {
  const { nodo, criterio, cotejado } = item;
  const [showMessage, setShowMessage] = useState(false);

  const { id, nombre, documentos, valorFijo } = criterio;
  const { esCotejadoRubros, setUploadRubro, uploadRubro, idSolicitud } = useCotejoStore();
  const sinNombreDocumento = documentos === "";
  const sinPuntaje = valorFijo === null;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState(new Set());

  const handleChecks = async (event) => {
    const { id } = event.target;
    const body = { idSolicitud, idItem: +id };

    await changeCriterio(body)
      .then(() => {
        console.log("Cambio exitoso");
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 2500);
      })
      .catch((error) => {
        console.error("Error al cambiar status de criterio para cotejo", error);
      });
  };

  const handleUploadSuccess = (idExpediente) => {
    setUploadedDocs((prev) => {
      const newSet = new Set(prev);
      newSet.add(idExpediente);
      return newSet;
    });
    setUploadRubro(!uploadRubro);
  };

  return (
    <>
      {showMessage && 
        <MessageModal 
          isOpen={showMessage}
          message={"Actualizado"} 
          onClose={() => setShowMessage(false)} 
        />
      }
      <tr key={id}>
        <td>{id}</td>
        <td>{replaceBrWithNewline(nombre)}</td>
        <td>
          {sinNombreDocumento ? (
            <p>Sin registro de documento probatorio</p>
          ) : (
            <label htmlFor={id} className={styles.label}>
              {replaceBrWithNewline(documentos)}
            </label>
          )}
        </td>
        <td>{sinPuntaje ? <p>Sin puntaje registrado para este ítem</p> : <p>{valorFijo}</p>}</td>
        <td className={styles.tdEvidencia}>
          <div className={styles.containerBtnPDF}>
            <button
              type="button"
              className={styles.buttonPdf}
              title="Ver PDF"
              onClick={() => {
                openModal();
                setUrlPdf(nodo);
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} color="green" />
            </button>
          </div>
        </td>
        {esCotejadoRubros ? (
          <td>
            <CargarEvidencia
              tipoUpload="criterio"
              idExpediente={Number(id)}
              idSolicitud={idSolicitud}
              onUploadSuccess={() => handleUploadSuccess(id)}
            />
          </td>
        ) : (
          <></>
        )}
        <td className={styles.tdCheckCotejo}>
          <input
            type="checkbox"
            className="checkbox_green"
            id={id}
            {...register(`${id}`)}
            onChange={handleChecks}
            defaultChecked={cotejado}
          />
        </td>
      </tr>
      {/* {subCriterios?.map(itemSubcriterio => {
        return (
          <RowTableCriteriosCotejo
            key={itemSubcriterio.id}
            item={itemSubcriterio}
            openModal={openModal}
            setUrlPdf={setUrlPdf}
            register={register}
          />
        );
      })} */}
    </>
  );
};

export default RowTableCriteriosCotejo;
