import React from "react";
import { Menu, useMenu } from "../../../../reutilizable/Menu";
import { Footer, Header } from "../../../layout";
import GenerarDocInconformidad from "../GenerarDocInconformidad/page/DocInconformidadPage";
import { BusquedaAvanzada } from "../../components/BusquedaAvanzada";
import GenerarReporte from "../GenerarReportes/page/GenerarReportePage";
import SubirDocumentosPage from "../../components/subir-documentos/page/SubirDocumentosPage";
import CotejoPage from "../../pages/CotejoPage";
import Loading from '../../../../reutilizable/Loading';
import Alert from '../../../../reutilizable/Alert';
import useMenuData from '../../../../hooks/useMenuData';
import styles from "./styles/SecretariaAdminSemsPage.module.css";
import VerExpedientePage from '../../pages/verExpediente/VerExpedientePage';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_URL_SUBMENUS = `${API_BASE_URL}/api/v1/actividad/secretaria_admin_sems`;

export const SecretariaAdminSemsPage = () => {

  const componentMap = {
    "Inconformidad": <GenerarDocInconformidad />,
    "Ver evaluaciones": <BusquedaAvanzada />,
    "Subir documentos": <SubirDocumentosPage />,
    "Solicitudes": <CotejoPage />,
    "Generar reportes": <GenerarReporte />,
    "Ver Expediente": <VerExpedientePage />
  }
  const { menu: menuData, menuConfig, error, isLoading } = useMenuData({
    apiUrl: API_URL_SUBMENUS,
    componentMap: componentMap,
    defaultSelectedValue: 'Inconformidad',
    isVertical: false
  });





  const menu = useMenu(menuData, menuConfig);

  return (
    <div className={styles.jefe_departamento_page_container}>
      <Header />
      <Menu menu={menu} />
      {isLoading && (<Loading />)}
      {error && (
        <Alert typeAlert={error.type}>
          <p>{error.message}</p>
        </Alert>
      )}
      {menu.element}
      <Footer />
    </div>
  );
};

