import { create } from "zustand";
import { persist } from "zustand/middleware";
import { getDatosParticipante } from "../../visorDeDocumentos/services/datosParticipanteService";

export const useDatosAcademico = create((set, get) => ({
  datosAcademico: null,
  datosAcademicoFull: null,
  setDataAcademico: async (codigo) => {
    try {
      const response = await getDatosParticipante(codigo);
      console.log("🚀 ~ setDataAcademico: ~ response:", response)
      const objDataAcademico = {
        idSolicitud: response.data.idSolicitud,
        codigo: response.data.codigo,
        nombre: response.data.nombre,
        apellidoPaterno: response.data.apellidoP,
        apellidoMaterno: response.data.apellidoM,
        tipoParitipacion: response.data.tipoParticipacionNombre,
        tipoParticipacionDescripcion: response.data.tipoParticipacionDescripcion,
      };
      console.log(objDataAcademico);
      set({ datosAcademico: objDataAcademico, datosAcademicoFull: response.data });
    } catch (error) {
      console.log("Error al obtener los datos del participante: ", error);
    }
  },

  // Función para verificar si datosAcademico tiene valores en sus propiedades (retorna true o false depende el caso)
  hasdatosAcademico: () => !!get().datosAcademico,
  // Función para resetear datosAcademico a null
  // resetdatosAcademico: () => set({ datosAcademico: null }),
  resetDatosAcademico: () => {
    set({
      datosAcademico: null,
      datosAcademicoFull: null,
    });
  },
}));
