import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useEvaluationStore } from '../../../store/useEvaluationStore';
import styles from './styles/Actas.module.css'
import { useForm } from 'react-hook-form';
import Modal from '../../../reutilizable/Modal';
import ViewerPDF from './ViewerPDF';
import Alert from '../../../reutilizable/Alert';



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_VISUALIZAR = `${API_BASE_URL}/api/v1/expediente/visualizar-acta`;
const API_ETAPA_DOCUMENTOS = `${API_BASE_URL}/api/v1/expediente/obtener-etapa-documentos`;


export const Actas = () => {
    const { selectedDataAcademico } = useEvaluationStore();
    const { handleSubmit, register, watch } = useForm();
    const [documentos, setDocumentos] = useState([])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [urlPdf, setUrlPdf] = useState(null);

    const openModal = () => setIsModalOpen(!isModalOpen);
    const closeModal = () => setIsModalOpen(!isModalOpen);

    const [error, setError] = useState(null);

    //cargar catalogo de documentos
    useEffect(() => {
        setError(null);
        const token = localStorage.getItem('accessToken');

        axios.get(API_ETAPA_DOCUMENTOS, {
            headers: {
                Accept: '*/*',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                setDocumentos(response.data)
            })
            .catch(error => console.error("Error fetching grados: ", error))

    }, [])

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError(""); // Limpiar el mensaje de error después de 3 segundos
            }, 3000);
            return () => clearTimeout(timer); // Limpiar el timeout si el componente se desmonta
        }
    }, [error]);


    //buscar actas
    const handleSubmitEvaluacion = async (data) => {
        console.log("buscar actas", data);
        const token = localStorage.getItem('accessToken');

        axios.get(`${API_VISUALIZAR}?idSolicitud=${selectedDataAcademico.id}&siglaEtapa=${data.documento}`, {
            headers: {
                Accept: '*/*',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob'
        })
            .then(response => {
                openModal();
                setUrlPdf(response.data);  // Ensure you're passing the response.data which is the actual blob
            })
            .catch(error => {
                setError({
                    message: "Acta no encontrada",
                    type: 'error'
                })
                console.error("Error fetching: ", error)
            });
    };


    return (
        <>
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                width='850px'
            >
                <ViewerPDF urlPdf={urlPdf} />
            </Modal>
            <form onSubmit={handleSubmit(handleSubmitEvaluacion)}>
                <div className={styles.restForm}>
                    <div className={styles.containerInputsSelectRadio}>
                        <div className={styles.inputSelect}>
                            <select name="docuemento" id="docuemtno" {...register('documento')}>
                                <option value="" disabled selected={true}>-- Seleccione su nombre de la lista --</option>
                                {documentos.map(documento => (
                                    <option key={documento.sigla} value={documento.sigla}>{documento.nombre}</option>
                                ))}
                            </select>
                            <input
                                type="submit"
                                value={'Visualizar'}
                                className={styles.inputSubirEvidencia}
                            />
                        </div>
                    </div>
                </div>

            </form>

            {error ?
                <Alert typeAlert={error.type}>
                    <p>{error.message}</p>
                </Alert>
                : ""
            }


        </>

    )
};
