import React, { useEffect, useState } from "react";
import { useEvaluationStore } from '../../../store/useEvaluationStore';
import { generatePDFDicFinal, uploadDictamenFinal } from "../services/dictamenService";
import styles from "./styles/DictamenFinal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../reutilizable/Modal";
import ViewerPDF from "../../../reutilizable/ViewerPDF";

const DictamenFinal = () => {
    const [dictamenFile, setDictamenFile] = useState(null);
    const [actaFile, setActaFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDictamenUploaded, setIsDictamenUploaded] = useState(false);
    const [isActaUploaded, setIsActaUploaded] = useState(false);
    const [dictamenNodoId, setDictamenNodoId] = useState(null);
    const [actaNodoId, setActaNodoId] = useState(null);

    const { selectedDataAcademico } = useEvaluationStore();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [urlPdf, setUrlPdf] = useState(null);

    const openModal = () => setIsModalOpen(!isModalOpen);
    const closeModal = () => setIsModalOpen(!isModalOpen);

    useEffect(() => {
        if (!selectedDataAcademico || !selectedDataAcademico.archivos) return;

        const archivos = selectedDataAcademico.archivos;

        const dictamen = archivos.find(archivo => archivo.sigla === 'I');
        const acta = archivos.find(archivo => archivo.sigla === 'J');

        setIsDictamenUploaded(!!dictamen);
        setIsActaUploaded(!!acta);

        if (dictamen) setDictamenNodoId(dictamen.nodoId);
        if (acta) setActaNodoId(acta.nodoId);

    }, [selectedDataAcademico]);

    if (!selectedDataAcademico) return null;

    const handleFileChange = (e, type) => {
        const file = e.target.files[0];

        if (!file) return;

        const allowedTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
        const maxFileSize = 2 * 1024 * 1024;

        if (!allowedTypes.includes(file.type)) {
            alert("Solo se permiten archivos en formato PDF o DOCX.");
            e.target.value = "";
            return;
        }

        if (file.size > maxFileSize) {
            alert("El tamaño del archivo no debe superar los 2 MB.");
            e.target.value = "";
            return;
        }

        if (type === "dictamen") setDictamenFile(file);
        if (type === "acta") setActaFile(file);
    };

    const handleFileUpload = async (type) => {
        setIsLoading(true);

        try {
            const formData = new FormData();
            if (type === "dictamen" && dictamenFile) {
                formData.append("archivo", dictamenFile);
                formData.append("idSolicitud", selectedDataAcademico.id);
                formData.append("dictamen", true);
                await uploadDictamenFinal(formData);
                alert("Archivo dictamen subido correctamente.");
                setDictamenFile(null);
                setIsDictamenUploaded(true);
            }
            if (type === "acta" && actaFile) {
                formData.append("archivo", actaFile);
                formData.append("idSolicitud", selectedDataAcademico.id);
                formData.append("dictamen", false);
                await uploadDictamenFinal(formData);
                alert("Archivo acta subido correctamente.");
                setActaFile(null);
                setIsActaUploaded(true);
            }
        } catch (error) {
            alert("Error al subir el archivo.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleGenerateDictamen = async () => {
        const userConfirmed = window.confirm(
            "Al generar el dictamen final se cerrará la evaluación, ¿estás seguro de continuar?"
        );

        if (userConfirmed) {
            setIsLoading(true);
            try {
                const payload = {
                    academicosSeleccionados: [selectedDataAcademico.codigo],
                    tipo: "inconformidad"
                };
                const response = await generatePDFDicFinal(payload);
                const blob = new Blob([response.data], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DictamenFinal.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                alert("Dictamen generado exitosamente.");
            } catch (error) {
                alert("Error al generar el dictamen final.");
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className={styles.container}>
            <Modal isOpen={isModalOpen} onClose={closeModal} width='850px'>
                <ViewerPDF urlPdf={urlPdf} />
            </Modal>

            <button
                className={styles.generateButton}
                onClick={handleGenerateDictamen}
                disabled={isLoading}
            >
                GENERAR DICTAMEN FINAL
            </button>

            <div className={styles.uploadSection}>
                <h2>Subir documentos</h2>

                <div className={styles.uploadRow}>
                    <label className={styles.label}>Dictamen</label>
                    {isDictamenUploaded ? (
                        <>
                            <button
                                type='button'
                                className={styles.buttonPdf}
                                title='Ver PDF'
                                onClick={() => {
                                    openModal();
                                    setUrlPdf(dictamenNodoId);
                                }}
                            >
                                <FontAwesomeIcon icon={faFilePdf} color='green' size='2xl' />
                            </button>
                            <p className={styles.uploadedMessage}>✅ Dictamen ya subido</p>
                        </>
                    ) : (
                        <>
                            <input
                                id="dictamenInput"
                                type="file"
                                className={styles.fileInput}
                                onChange={(e) => handleFileChange(e, "dictamen")}
                                accept=".pdf,.docx"
                                disabled={isLoading}
                            />
                            <button
                                className={styles.uploadButton}
                                onClick={() => handleFileUpload("dictamen")}
                                disabled={!dictamenFile || isLoading}
                            >
                                Subir
                            </button>
                        </>
                    )}
                </div>

                <div className={styles.uploadRow}>
                    <label className={styles.label}>Acta firmada</label>
                    {isActaUploaded ? (
                        <>
                            <button
                                type='button'
                                className={styles.buttonPdf}
                                title='Ver PDF'
                                onClick={() => {
                                    openModal();
                                    setUrlPdf(actaNodoId);
                                }}
                            >
                                <FontAwesomeIcon icon={faFilePdf} color='green' size='2xl' />
                            </button>
                            <p className={styles.uploadedMessage}>✅ Acta firmada ya subida</p>
                        </>
                    ) : (
                        <>
                            <input
                                id="actaInput"
                                type="file"
                                className={styles.fileInput}
                                onChange={(e) => handleFileChange(e, "acta")}
                                accept=".pdf,.docx"
                                disabled={isLoading}
                            />
                            <button
                                className={styles.uploadButton}
                                onClick={() => handleFileUpload("acta")}
                                disabled={!actaFile || isLoading}
                            >
                                Subir
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DictamenFinal;
