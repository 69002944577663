import api from "../../../hooks/api";

export const generatePDFDicNoParticipant = async (body) => {
  try {
    const response = await api.post(
      `/api/v1/dictamen/dictamen-no-participante`,
      body, {
        responseType: "blob",
      }
    );
    if (response.status === 200) {
      return response;
    }

    throw new Error("Error al generar el PDF del dictamen de no participantes");
  } catch (error) {
    console.log("S: Error al generar el PDF:", error);
    throw new Error("Error al generar el PDF");
  }
};

export const generatePDFDicFinal = async (body) => {
  try {
    const response = await api.post(`/api/v1/dictamen/dictamen-final`, body, {
      responseType: 'blob',
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error("Error al generar el PDF:", error);
    throw new Error("Error al generar el PDF");
  }
};

export const uploadDictamenNoParticipante = async (body) => {
  try {
    const response = await api.post(
      "/api/v1/dictamen/subir-dictamen-no-participante",
      body
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error al subir el archivo:", error);
    throw new Error("Error al subir el archivo");
  }
};

export const uploadDictamenFinal = async (body) => {
  try {
    const response = await api.post(
      "/api/v1/dictamen/subir-dictamen-final",
      body
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error al subir el archivo:", error);
    throw new Error("Error al subir el archivo");
  }
};

export const getStatusDictamenService = async (idSolicitud, tipo, signal) => {
  try {
    const response = await api.get(
      `/api/v1/dictamen/archivo/${idSolicitud}/${tipo}`,
      { signal }
    );

    if (response.status === 200) {
      return response.data;
    }

    throw Error;
  } catch (error) {
    console.error("Error al obtener el estado del dictamen:", error);
    throw new Error("Error al obtener el estado del dictamen");
  }
};
