import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import EliminarEvidencia from "./EliminarEvidencia";
import CargarEvidencia from "./CargarEvidencia";
import { replaceBrWithNewline } from "../../../../utils";
import styles from "./styles/RowTablaItemCriterio.module.css";
import useStatusStore from "../../../../store/useStatusStore";

const RowTablaItemCriterio = ({
  item,
  openModal,
  uploadedDocs,
  setUrlPdf,
  idSolicitud,
  handleUploadSuccess,
  handleDeleteSuccess,
}) => {
  const { requestStatus } = useStatusStore();
  const { criterio, nodo, subCriterios } = item;
  const { id: idExpediente, nombre, documentos, valorFijo } = criterio;
  const SOLICITUD_ENVIADA = 3
  const existeDocumento = nodo !== null || uploadedDocs.has(idExpediente);
  const sinNombreDocumento = !documentos || documentos === "";
  const sinPuntaje = valorFijo === null;

  return (
    <>
      <tr>
        <td>{idExpediente}</td>
        <td>{replaceBrWithNewline(nombre)}</td>
        <td>{sinNombreDocumento ? <p>Sin registro de documento probatorio</p> : replaceBrWithNewline(documentos)}</td>
        <td>{sinPuntaje ? <p>Sin puntaje registrado para este ítem</p> : <p>{valorFijo}</p>}</td>
        <td className={styles.tdEvidencia}>
          {existeDocumento ? (
            <div className={styles.containerBtnPDF}>
              <button
                type="button"
                className={styles.buttonPdf}
                title="Ver PDF"
                onClick={() => {
                  openModal();
                  setUrlPdf(nodo);
                }}
              >
                <FontAwesomeIcon icon={faFilePdf} color='green' />
              </button>
            </div>
          ) : requestStatus < SOLICITUD_ENVIADA ? (
            <CargarEvidencia
              tipoUpload="criterio"
              idExpediente={Number(idExpediente)}
              idSolicitud={idSolicitud}
              onUploadSuccess={() => handleUploadSuccess(idExpediente)}
            />
          ) : (
            <></>
          )}
        </td>
        {requestStatus < SOLICITUD_ENVIADA && (
          <td className={styles.tdEliminarEvidencia}>
            <EliminarEvidencia
              tipoDelete="criterio"
              idExpediente={idExpediente}
              idSolicitud={idSolicitud}
              activo={existeDocumento}
              onDeleteSuccess={() => handleDeleteSuccess(idExpediente)}
            />
          </td>
        )}
      </tr>
      {/* {subCriterios?.map(subCriterio => {
          return (
            <RowTablaItemCriterio
              key={subCriterio.criterio.id}
              item={subCriterio}
              openModal={openModal}
              uploadedDocs={uploadedDocs}
              setUrlPdf={setUrlPdf}
              idSolicitud={idSolicitud}
              idExpediente={subCriterio.id}
              handleDeleteSuccess={handleDeleteSuccess}
              handleUploadSuccess={handleUploadSuccess}
            />
          );
        })} */}
    </>
  );
};

export default RowTablaItemCriterio;
