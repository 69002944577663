import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import styles from './styles/RowTableExpedienteRequisitos.module.css';

const RowTableExpedienteRequisitos = ({
    requisito, existeDocumento, openModal, setUrlPDF, idSolicitud, handleUploadSuccess, handleDeleteSuccess
}) => {

    return (
        <tr key={requisito.id}>
            <td>{requisito.id}</td>
            <td>{requisito.nombre}</td>
            <td className={styles.tdTablaExpedienteRequisitos}>
                {existeDocumento ? (
                    <div className={styles.containerBtnPDF}>
                        <button
                            type="button"
                            className={styles.buttonPdf}
                            title="Ver PDF"
                            onClick={() => {
                                openModal();
                                setUrlPDF(requisito.nodo || '');
                            }}
                        >
                            <FontAwesomeIcon icon={faFilePdf} color="red" />
                        </button>
                    </div>
                ) : (
                    <p>N/A</p>
                )}
            </td>
        </tr>
    );
};

export default RowTableExpedienteRequisitos;