import React, { useEffect, useState } from "react";
import { Footer, Header } from "../../layout";
import styles from "./styles/HackAcademico.module.css";
import { Menu, useMenu } from "../../../reutilizable/Menu";
import axios from "axios";
import EnviarSolicitud from "../../academico/enviarSolicitud/pages/EnviarSolicitud";
import { VisualizarSolicitud } from "../../academico/visualizarSolicitud/components/visualizarSolicitud";
import ExpedienteRequisitosPage from "../../academico/expediente/pages/ExpedienteRequisitosPage";
import ExpedienteCriteriosPage from "../../academico/expediente/pages/ExpedienteCriteriosPage";
import PlanTrabajoPage from "../../academico/planTrabajo/pages/planTrabajoPage";
import { Solicitud } from "../../academico/llenarSolicitud/components/Solicitud";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_URL_SUBMENUS = `${API_BASE_URL}/api/v1/actividad/hack_academico`;

export const HackAcademicoPage = () => {


  const [menuResponse, setMenuResponse] = useState([]);

  const componentMap = {
    Enviar: <EnviarSolicitud />,
    Crear: <Solicitud />,
    Consultar: <VisualizarSolicitud />,
    "Plan de trabajo": <PlanTrabajoPage />,
    Requisitos: <ExpedienteRequisitosPage />,
    "Rubros de evaluación": <ExpedienteCriteriosPage />,
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(API_URL_SUBMENUS, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const sortedMenus = (response.data.menus || []).map((menu) => ({
          ...menu,
          submenus: Array.isArray(menu.submenus)
            ? menu.submenus
                .map((submenu) => ({
                  ...submenu,
                }))
                .sort((a, b) => (a.orden === b.orden ? a.id - b.id : a.orden - b.orden))
            : [],
        })).sort((a, b) => (a.orden === b.orden ? a.id - b.id : a.orden - b.orden));

        setMenuResponse(sortedMenus);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenuData();
  }, []);

  const menu = useMenu(
    menuResponse.map((menu) => ({
      label: menu.nombreCorto,
      ...(menu.submenus.length > 0
        ? {
            children: menu.submenus.map((submenu) => ({
              label: submenu.nombreCorto,
              element: componentMap[submenu.nombreCorto] || <p>{submenu.nombreCorto}</p>,
            })),
          }
        : {
            element: componentMap[menu.nombreCorto] || <p>{menu.nombreCorto}</p>,
          }),
    })),
    { selectedValue: "Instrucciones", isVertical: false }
  );

  return (
    <div className={styles.jefe_departamento_page_container}>
      <Header />
      <Menu menu={menu} />
      {menu.element}
      <Footer />
    </div>
  );

  
};
