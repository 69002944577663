import React, { useEffect, useState } from "react";
import axios from "axios";

import Loading from "../../../../../reutilizable/Loading";
import AlertaDescargandoDoc from "../../../components/AlertaDescargandoDoc";
import { useEvaluationStore } from "../../../../../store/useEvaluationStore";
import {
  generarDocumentoSolicitud,
  cargarDocumento,
} from "../services/generarDocumentoService";
import styles from "../../../components/styles/DescargarDocumentoSolicitud.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../../reutilizable/Modal";
import ViewerPDF from "../../../../../reutilizable/ViewerPDF";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const GET_STATUS = `${API_BASE_URL}/api/v1/cotejo-documentos/status?idSolicitud=`;


const GenerarInconformidad = () => {
  const { selectedDataAcademico } = useEvaluationStore();

  const [idSolicitud, setIdSolicitud] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mensajeDescarga, setMensajeDescarga] = useState(null);
  const [mensajeCarga, setMensajeCarga] = useState(null);
  const [cargaHabilitada, setCargaHabilitada] = useState(true);
  const [dictamenFile, setDictamenFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [documentoNodoId, setDocumentoNodoId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);
  const [isSolicitudUploaded, setIsSolicitudUploaded] = useState(false);
  const [status, setStatus] = useState();

  const [upploadSuccess, setUpploadSuccess] = useState(false);
  
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(`${GET_STATUS}${selectedDataAcademico.id}`, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setStatus(response.data)
      })
      .catch(error => console.error("Error fetching criterios: ", error));
  }, [selectedDataAcademico]);

  useEffect(() => {
    if (!selectedDataAcademico) return;

    setIdSolicitud(selectedDataAcademico.id);

    const archivoInconformidad = selectedDataAcademico.archivos?.find(
      (archivo) => archivo.sigla === "G"
    );
    setIsSolicitudUploaded(!!archivoInconformidad);
    setCargaHabilitada(!archivoInconformidad);

    if (archivoInconformidad) {
      setDocumentoNodoId(archivoInconformidad.nodoId);
    }
  }, [selectedDataAcademico]);

  const descargarSolicitudAcademico = async () => {
    setMensajeDescarga(null);
    setLoading(true);

    try {
      const response = await generarDocumentoSolicitud(idSolicitud);
      console.log(response);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");

        setMensajeDescarga({
          type: "success",
          mensaje: "Se ha descargado el documento de solicitud",
        });
      }
    } catch (error) {
      console.error("Error al descargar documento de inconformidad: ", error);
      setMensajeDescarga({
        type: "error",
        mensaje: "Ocurrió un error al generar el documento de inconformidad",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async () => {
    setMensajeCarga(null);
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("idSolicitud", idSolicitud);
      formData.append("siglaDocumento", "G");
      formData.append("archivo", dictamenFile);

      const response = await cargarDocumento(formData);

      if (response?.status === 200) {
        setMensajeCarga({
          type: "success",
          mensaje: "Documento cargado exitosamente.",
        });
        setDictamenFile(null);
        document.getElementById("fileInput").value = "";
        setCargaHabilitada(false);
        setIsSolicitudUploaded(true);

        setUpploadSuccess(!upploadSuccess);
      } else {
        setMensajeCarga({
          type: "error",
          mensaje: `Error al cargar el documento. Código: ${response?.status}`,
        });
      }
    } catch {
      setMensajeCarga({
        type: "error",
        mensaje: "Error al cargar el documento. Intenta nuevamente.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const allowedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const maxFileSize = 2 * 1024 * 1024;

    if (!allowedTypes.includes(file.type)) {
      alert("Solo se permiten archivos en formato PDF o DOCX.");
      e.target.value = "";
      return;
    }

    if (file.size > maxFileSize) {
      alert("El tamaño del archivo no debe superar los 2 MB.");
      e.target.value = "";
      return;
    }

    setDictamenFile(file);
  };

  return (
    <div className={styles.container}>
      <Modal isOpen={isModalOpen} onClose={closeModal} width="850px">
        <ViewerPDF urlPdf={urlPdf} />
      </Modal>

      <button
        onClick={descargarSolicitudAcademico}
        disabled={loading || isSolicitudUploaded}
        className={
          (!isSolicitudUploaded || status !== 3) ?
            `${styles.btnDescargar}` : `${styles.btnDescargaDisabled}`}
      >
        Generar Inconformidad
      </button>

      <div className={styles.uploadSection}>
        <h2>Subir documentos</h2>

        <div className={styles.uploadRow}>
          <label htmlFor="fileInput" className={styles.label}>
            Inconformidad y acuse
          </label>

          {cargaHabilitada ? (
            <>
              <input
                id="fileInput"
                type="file"
                className={styles.fileInput}
                onChange={handleFileChange}
                accept=".pdf,.docx"
                disabled={isLoading}
              />
              <button
                className={styles.uploadButton}
                onClick={handleFileUpload}
                disabled={!dictamenFile || isLoading}
              >
                Subir cotejo
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className={styles.buttonPdf}
                title="Ver PDF"
                onClick={() => {
                  openModal();
                  setUrlPdf(documentoNodoId);
                }}
              >
                <FontAwesomeIcon icon={faFilePdf} color="green" size="2xl" />
              </button>
              <p className={styles.uploadedMessage}>✅ Documento ya subido</p>
            </>
          )}
        </div>
      </div>

      {loading && <Loading />}

      {mensajeDescarga && (
        <AlertaDescargandoDoc mensajeDescarga={mensajeDescarga} />
      )}
      {mensajeCarga && <AlertaDescargandoDoc mensajeDescarga={mensajeCarga} />}
    </div>
  );
};

export default GenerarInconformidad;
