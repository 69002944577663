import { useState, useCallback } from "react";
import axios from "axios";
import { getAccessToken } from "../../../../authService";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_PUBLICACION_FINAL_URL = `${API_BASE_URL}/api/v1/gaceta/descargar`;

export const usePublicacionFinal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPublicacionFinal = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const token = await getAccessToken();
      const response = await axios.get(API_PUBLICACION_FINAL_URL, {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "publicacion_final.xls");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      console.error("Error al descargar la Publicación Final: ", err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { fetchPublicacionFinal, isLoading, error };
};
