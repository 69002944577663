import React, { useEffect, useState } from 'react';
import { useEvaluationStore } from '../../../../../store/useEvaluationStore';
import arbolCriteriosJson from '../../../../evaluacion/services/arbolCriterios.json';
import { datosEvaluacion } from '../services/evaluacionService';

import styles from '../styles/VisualizacionAsignacionNivel.module.css';
import TablaResultadosEvaluacion from '../../../../evaluacion/components/TablaResultadosEvaluacion';
import Alert from '../../../../../reutilizable/Alert';

const VisualizacionAsignacionNivel = () => {
    const { selectedDataAcademico } = useEvaluationStore();

    const [resultadosEvaluacion, setResultadosEvaluacion] = useState();
    const [arbolCriterios, setArbolCriterios] = useState([]);
    const [miembros, setMiembros] = useState([]);
    const [puntaje, setPuntaje] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("")


    useEffect(() => {
        if (!selectedDataAcademico?.id) return;

        setLoading(true);
        setError('')
        datosEvaluacion(selectedDataAcademico.id)
            .then(data => {
                setMiembros(data.miembros);
                setArbolCriterios(data.arbolCriterios);
                setResultadosEvaluacion(data.tablaResultados);
                setPuntaje(data.puntaje);
            })
            .catch(error => setError(error.message))
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return <div className={styles.textCenter}><p>Cargando...</p></div>;
    }

    return (
        <div className={styles.restForm}>
            {error && <Alert typeAlert='error'>{error}</Alert>}
            <div className={styles.submit}>
                <TablaResultadosEvaluacion
                    resultados={resultadosEvaluacion}
                    nivel={arbolCriteriosJson.nivelPrimerEvaluacion}
                />
            </div>
        </div>
    );
};

export default VisualizacionAsignacionNivel;
