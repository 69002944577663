export const INCUMPLIMIENTO_TYPES = {
  SET_ACADEMIC: "SET_ACADEMIC",
  SET_RESPONSE: "SET_RESPONSE",
  MODAL: "MODAL",
  SET_BREACHES: "SET_BREACHES",
  UPDATE_BREACH: "UPDATE_BREACH",
  ADD_BREACH_TO_DELETE: "ADD_BREACH_TO_DELETE",
  DELETE_BREACH_TO_DELETE: "DELETE_BREACH_TO_DELETE",
  SET_CONDITIONS: "SET_CONDITIONS",
  SET_BREACHES_TO_DELETE: "SET_BREACHES_TO_DELETE",
  SET_BREACHES_TO_UPDATE: "SET_BREACHES_TO_UPDATE",
};
