import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import EliminarEvidencia from "./EliminarEvidencia";
import CargarEvidencia from "./CargarEvidencia";
import styles from "./styles/RowTableExpedienteRequisitos.module.css";
import useStatusStore from "../../../../store/useStatusStore";

const RowTableExpedienteRequisitos = ({
  requisito,
  existeDocumento,
  openModal,
  setUrlPDF,
  idSolicitud,
  handleUploadSuccess,
  handleDeleteSuccess,
}) => {
  const { requestStatus } = useStatusStore();
  const SOLICITUD_ENVIADA = 3
  console.log("status on requisitos: ", requestStatus);

  return (
    <tr key={requisito.id}>
      <td>{requisito.orden}</td>
      <td>{requisito.nombre}</td>
      <td className={styles.tdTablaExpedienteRequisitos}>
        {existeDocumento ? (
          <div className={styles.containerBtnPDF}>
            <button
              type="button"
              className={styles.buttonPdf}
              title="Ver PDF"
              onClick={() => {
                openModal();
                setUrlPDF(requisito.nodo || "");
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} color="green" />
            </button>
          </div>
        ) : requestStatus < SOLICITUD_ENVIADA ? (
          <CargarEvidencia
            idExpediente={requisito.id}
            idSolicitud={idSolicitud}
            tipoUpload="requisito"
            onUploadSuccess={() => handleUploadSuccess(requisito.id)}
          />
        ) : <></>}
      </td>
      {requestStatus < SOLICITUD_ENVIADA && (
        <td className={styles.tdEliminarEvidencia}>
          <EliminarEvidencia
            tipoDelete={"requisito"}
            idSolicitud={idSolicitud}
            idExpediente={requisito.id}
            activo={existeDocumento}
            onDeleteSuccess={() => handleDeleteSuccess(requisito.id)}
          />
        </td>
      )}
    </tr>
  );
};

export default RowTableExpedienteRequisitos;
