import api from "../hooks/api";

const url = `/api/v1/reportes/obtener-lista-resultados-evaluaciones`;
const URL_EVALUATION = `/api/v1/reportes/generar_reporte_evaluacion`;

export const getResultadoEvaluacionList = async () => {
  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const postResultadoEvaluacion = async (body) => {
  try {
    const response = await api.post(URL_EVALUATION, body, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
