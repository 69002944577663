import React, { useEffect, useState } from 'react';
import Modal from '../../../../reutilizable/Modal';
import ContentModalSeleccionarModalidad from './ContentModalSeleccionarModalidad';
import { useModalidadEvaluacion } from '../store/useModalidadEvaluacionStore';
import { setTipoDeParticipacion } from '../services/modalidadEvaluacionService';
import { ERROR_MESSAGES_GENERICS_API } from '../../../../utils/messagesFromAPI';
import styles from './styles/ModalidadEvaluacion.module.css';

const ModalidadEvaluacion = ({setStatus}) => {

  const { getModalidadesEvaluacion } = useModalidadEvaluacion();

  const [isArt29ModalOpen, setIsArt29ModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mensaje, setMensaje] = useState({
    type: null,
    mensaje: null
  })

  const openModal = () => setIsModalOpen(!isModalOpen);
  const onClose = () => setIsModalOpen(!isModalOpen);

  const openModalArt29 = () => setIsArt29ModalOpen(!isArt29ModalOpen)
  const onCloseModalArt29 = () => setIsArt29ModalOpen(!isArt29ModalOpen)

  useEffect(() => {
    getModalidadesEvaluacion();
  }, [getModalidadesEvaluacion])


  const handleClick = async (idTipoParticipacion) => {
   /* if (idTipoParticipacion === 1) {
      await setTipoDeParticipacion(idTipoParticipacion)
          .then(response => {
            if (response.status === 200) {
              setMensaje({
                type: 'success',
                mensaje: 'El tipo de participación se ha establecido correctamente'
              })
            }
          })
          .catch(error => {
            if (error.response) {
              const message = ERROR_MESSAGES_GENERICS_API[error.response.status] || ERROR_MESSAGES_GENERICS_API.default;
              setMensaje({
                type: 'error',
                mensaje: message
              });
            }
            console.error('Error al establecer el tipo de participación', error)
          });
      return;
    }*/

    if (idTipoParticipacion === 2) {
      openModal();
      return;
    }else if (idTipoParticipacion === 1){
      openModalArt29();
      return;
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.titulo}>Seleccione la modalidad bajo la cual desea participar:</p>

      <form className={styles.formulario}>
        <div className={styles.containerButtons}>
          <button
            className={styles.buttonModalidad}
            type='button'
            onClick={() => handleClick(1)}
          >
            Art. 29 <br /> (Evaluación)
          </button>

          <button
            className={styles.buttonModalidad}
            type='button'
            onClick={() => handleClick(2)}
          >
            Art. 26 <br />(Perfil PRODEP)
          </button>
        </div>

        <p className={styles.textoAdvertencia}>
          NOTA: favor de verificar la modalidad por la cual <span className={styles.br}></span>
          solicitará participar, pues una vez seleccionada, no se <span className={styles.br}></span>
          podrá modificar el expediente que se haya cargado.
        </p>
      </form>

      <Modal isOpen={isModalOpen} onClose={onClose} >
        <ContentModalSeleccionarModalidad
          onClose={onClose}
          setStatus={setStatus}
          articulo={26}
        />
      </Modal>

      <Modal isOpen={isArt29ModalOpen} onClose={onCloseModalArt29} >
        <ContentModalSeleccionarModalidad
          onClose={onCloseModalArt29}
          setStatus={setStatus}
          articulo={29}
        />
      </Modal>
    </div>
  );
};

export default ModalidadEvaluacion;
