export const ERROR_MESSAGES_GENERICS_API = {
  100: 'Continúa la solicitud. Esperando más información.',
  101: 'Cambiando de protocolos según la solicitud del cliente.',
  200: 'Operación exitosa.',
  201: 'Recurso creado correctamente.',
  202: 'Solicitud aceptada, pero aún no procesada.',
  203: 'Información no autorizada proporcionada.',
  204: 'Solicitud exitosa, sin contenido para mostrar.',
  205: 'Restablece el contenido visualizado.',
  206: 'Respuesta parcial entregada.',
  300: 'Múltiples opciones disponibles para esta solicitud.',
  301: 'El recurso solicitado se movió de forma permanente.',
  302: 'El recurso solicitado se encuentra temporalmente en otro lugar.',
  303: 'Consulta otros recursos utilizando un método GET.',
  304: 'El contenido no ha cambiado desde la última solicitud.',
  307: 'Redirección temporal, utiliza el mismo método.',
  308: 'Redirección permanente, utiliza el mismo método.',
  400: 'Solicitud incorrecta. Por favor, revisa los datos ingresados.',
  401: 'No estás autorizado para acceder a este recurso. Inicia sesión.',
  402: 'Se requiere un pago para completar la solicitud.',
  403: 'No tienes permisos para acceder a este recurso.',
  404: 'Recurso no encontrado. Verifica la URL.',
  405: 'Método HTTP no permitido para este recurso.',
  406: 'El contenido solicitado no es aceptable.',
  407: 'Se requiere autenticación de proxy.',
  408: 'La solicitud tardó demasiado y expiró.',
  409: 'Conflicto al procesar la solicitud.',
  410: 'El recurso ya no está disponible y no regresará.',
  411: 'La longitud del contenido es obligatoria.',
  412: 'Una condición previa no fue cumplida.',
  413: 'El contenido enviado es demasiado grande.',
  414: 'La URI solicitada es demasiado larga.',
  415: 'Tipo de medio no soportado.',
  416: 'El rango solicitado no está disponible.',
  417: 'La expectativa no pudo ser satisfecha.',
  418: 'Soy una tetera. Intenta algo más serio. ☕',
  422: 'Entidad no procesable. Verifica los datos enviados.',
  423: 'El recurso está bloqueado.',
  424: 'Dependencia fallida en la solicitud.',
  425: 'La solicitud es demasiado temprana.',
  426: 'Se requiere una actualización del protocolo.',
  428: 'Se requiere una condición previa.',
  429: 'Demasiadas solicitudes. Intenta más tarde.',
  431: 'Los encabezados de la solicitud son demasiado grandes.',
  451: 'El recurso no está disponible por razones legales.',
  500: 'Error interno del servidor. Inténtalo más tarde.',
  501: 'El servidor no implementa la funcionalidad solicitada.',
  502: 'Error en la puerta de enlace.',
  503: 'El servicio no está disponible en este momento.',
  504: 'El servidor no respondió a tiempo.',
  505: 'Versión HTTP no soportada.',
  506: 'La negociación de variantes generó un bucle infinito.',
  507: 'El servidor no tiene espacio suficiente para completar la solicitud.',
  508: 'Se detectó un bucle infinito.',
  510: 'Faltan extensiones necesarias para la solicitud.',
  511: 'Se requiere autenticación de red.',
  default: 'Ocurrió un error inesperado. Inténtalo más tarde.',
};
