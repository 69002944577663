import React, { useEffect } from "react";
import Aside from "../../../reutilizable/Aside";
import { Menu, useMenu } from '../../../reutilizable/Menu';
import Mensaje from '../../../reutilizable/components/Mensaje';
import { useEvaluationStore } from '../../../store/useEvaluationStore';
import { DatosParticipante } from '../../visorDeDocumentos/components/DatosParticipante';
import SolicitudPage from './pages/SolicitudPage';
import { Requisitos } from '../../contralor/components/Requisitos';
import Evaluar from '../../contralor/components/Evaluar';
import styles from "./styles/expediente.module.css";

const Expediente = () => {
  const { selectedDataAcademico } = useEvaluationStore();
  const hasSelected = useEvaluationStore(state => state.hasSelectedDataAcademico());
  const resetSelectedDataAcademico = useEvaluationStore(state => state.resetSelectedDataAcademico);

  const menu = useMenu(
    [
      {
        label: 'Datos del académico',
        element: <DatosParticipante />
      },
      {
        label: 'Solicitud',
        element: <SolicitudPage />
      },
      {
        label: 'Documentos sujetos a evaluación',
        children: [
          {
            label: 'Requisitos',
            element: <Requisitos />
          },
          {
            label: 'Rubros de evaluación',
            element: <Evaluar withHead={false} />
          },
        ],
      }
    ],
    {
      customClass: {
        menu: styles.menuContainer,
        menuOption: styles.menuOption,
        subMenuOption: styles.subMenuOption,
        selected: styles.selected,
        subMenu: styles.subMenu
      },
    }
  );

  useEffect(() => {
    resetSelectedDataAcademico();
  }, [resetSelectedDataAcademico])

  return (
    <div className={styles.container}>
      <div className={styles.containerAside}>
        <Aside selectVisible={true} alias="ver_expediente" />
      </div>

      {hasSelected ? (
        <div className={styles.containerContent}>
          <div className={styles.containerMenu}>
            {selectedDataAcademico?.nombre && (
              <div className={styles.nombreAcademico}>
                <p>{`${selectedDataAcademico?.nombre} ${selectedDataAcademico?.apellidoPaterno} ${selectedDataAcademico?.apellidoMaterno}`}</p>
              </div>
            )}

            <div className={styles.menu}>
              <Menu menu={menu} />
              <div className={styles.optionMenu}>
                {menu.element}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Mensaje nombreModulo={'Ver Expediente'} />
      )}
    </div>
  );
};

export default Expediente
