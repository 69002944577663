import { useEffect, useState } from "react";
import { useEvaluationStore } from "../../../store/useEvaluationStore";
import { useDictamenNoParticipantes } from "../hooks/useDictamenNoParticipantes";
import styles from "./styles/DicNoParticipante.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../reutilizable/Modal";
import ViewerPDF from "../../../reutilizable/ViewerPDF";

export const DictamenNoParticipantes = () => {
    const { selectedDataAcademico } = useEvaluationStore();
    const { handleFileChange, handleFileUpload, handleGenerateDictamen, isLoading, dictamenFile, actaFile } = useDictamenNoParticipantes();

    const [isDictamenUploaded, setIsDictamenUploaded] = useState(false);
    const [isActaUploaded, setIsActaUploaded] = useState(false);
    const [dictamenNodoId, setDictamenNodoId] = useState(null);
    const [actaNodoId, setActaNodoId] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [urlPdf, setUrlPdf] = useState(null);

    const openModal = () => setIsModalOpen(!isModalOpen);
    const closeModal = () => setIsModalOpen(!isModalOpen);

    useEffect(() => {
        if (!selectedDataAcademico || !selectedDataAcademico.archivos) return;

        const archivos = selectedDataAcademico.archivos;

        const dictamen = archivos.find(archivo => archivo.sigla === 'I');
        const acta = archivos.find(archivo => archivo.sigla === 'J');

        setIsDictamenUploaded(!!dictamen);
        setIsActaUploaded(!!acta);

        if (dictamen) setDictamenNodoId(dictamen.nodoId);
        if (acta) setActaNodoId(acta.nodoId);

    }, [selectedDataAcademico]);

    if (!selectedDataAcademico) return null;

    return (
        <div className={styles.container}>
            <Modal isOpen={isModalOpen} onClose={closeModal} width='850px'>
                <ViewerPDF urlPdf={urlPdf} />
            </Modal>

            <button
                className={styles.generateButton}
                onClick={handleGenerateDictamen}
                disabled={isLoading}
            >
                GENERAR DICTAMEN DE NO PARTICIPANTE
            </button>

            <div className={styles.uploadSection}>
                <h2>Subir documentos</h2>

                <div className={styles.uploadRow}>
                    <label className={styles.label}>Dictamen</label>
                    {isDictamenUploaded ? (
                        <>
                            <button
                                type='button'
                                className={styles.buttonPdf}
                                title='Ver PDF'
                                onClick={() => {
                                    openModal();
                                    setUrlPdf(dictamenNodoId);
                                }}
                            >
                                <FontAwesomeIcon icon={faFilePdf} color='green' size='2xl' />
                            </button>
                            <p className={styles.uploadedMessage}>✅ Dictamen ya subido</p>
                        </>
                    ) : (
                        <>
                            <input
                                id="dictamenInput"
                                type="file"
                                className={styles.fileInput}
                                onChange={(e) => handleFileChange(e, "dictamen")}
                                accept=".pdf,.docx"
                                disabled={isLoading}
                            />
                            <button
                                className={styles.uploadButton}
                                onClick={() => handleFileUpload("dictamen")}
                                disabled={!dictamenFile || isLoading}
                            >
                                Subir
                            </button>
                        </>
                    )}
                </div>

                <div className={styles.uploadRow}>
                    <label className={styles.label}>Acta firmada</label>
                    {isActaUploaded ? (
                        <>
                            <button
                                type='button'
                                className={styles.buttonPdf}
                                title='Ver PDF'
                                onClick={() => {
                                    openModal();
                                    setUrlPdf(actaNodoId);
                                }}
                            >
                                <FontAwesomeIcon icon={faFilePdf} color='green' size='2xl' />
                            </button>
                            <p className={styles.uploadedMessage}>✅ Acta firmada ya subida</p>
                        </>
                    ) : (
                        <>
                            <input
                                id="actaInput"
                                type="file"
                                className={styles.fileInput}
                                onChange={(e) => handleFileChange(e, "acta")}
                                accept=".pdf,.docx"
                                disabled={isLoading}
                            />
                            <button
                                className={styles.uploadButton}
                                onClick={() => handleFileUpload("acta")}
                                disabled={!actaFile || isLoading}
                            >
                                Subir
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
