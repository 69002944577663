import axios from "axios";
import React, { useEffect, useState } from "react";
import api from "../../../hooks/api";
import Alert from "../../../reutilizable/Alert";
import Loading from "../../../reutilizable/Loading";
import { useInconformidadStore } from "../../../store/useInconformidadStore";
import styles from "./styles/Inconformarse.module.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_URL_DATOS_INCONFORMARSE = `${API_BASE_URL}/api/v1/evaluacion/datos-inconformarse`;
const API_URL_POST_INCONFORMARSE = `${API_BASE_URL}/api/v1/evaluacion/inconformarse`;

function parseDate(dateString) {
  if (!dateString) return null;
  const isYearFirst = /^\d{4}-\d{2}-\d{2}$/.test(dateString); // yyyy-MM-dd
  let year, month, day;
  if (isYearFirst) {
    [year, month, day] = dateString.split('-');
  } else {
    [day, month, year] = dateString.split('-');
  }
  return { year, month, day };
}

export const Inconformarse = ({ idSolicitud }) => {
  const { datosInconformidad, fetchDatosInconformidad, isLoading } = useInconformidadStore();
  const [formData, setFormData] = useState({
    recibida: "",
    fecha: "",
    area: "",
    documento: null,
    nombreDocumento: ""
  });
  const [isLoadingAcademico, setIsLoadingAcademico] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => { setIsVisible(false); }, 2000);
    return () => clearTimeout(timer);
  }, [isVisible]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => { setError(""); }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    fetchDatosInconformidad();
    api.get(`${API_URL_DATOS_INCONFORMARSE}?codigo=${localStorage.getItem('userName' || '')}`)
      .then(response => {
        if (response.data.fechaRecibido && response.data.fechaInconformidad) {
          const recibidaParsed = parseDate(response.data.fechaRecibido);
          const inconformidadParsed = parseDate(response.data.fechaInconformidad);

          setFormData({
            recibida: `${recibidaParsed.year}-${recibidaParsed.month}-${recibidaParsed.day}` || "",
            fecha: `${inconformidadParsed.year}-${inconformidadParsed.month}-${inconformidadParsed.day}` || "",
            area: response.data.razones || "",
            documento: null,
            nombreDocumento: response.data.nombreArchivo,
          });
          setIsLoadingAcademico(false);
        } else {
          setFormData({
            recibida: "",
            fecha: "",
            area: response.data.razones || "",
            documento: null,
            nombreDocumento: "",
          });
        }
      })
      .catch(error => {
        setFormData({
          recibida: "",
          fecha: "",
          area: "",
          documento: null,
          nombreDocumento: ""
        });
        console.error("Error fetching data: ", error);
      });
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      documento: e.target.files[0],
      nombreDocumento: e.target.files[0]?.name || ""
    }));
  };

  const validateFields = () => {
    let hasError = false;
    Object.keys(formData).forEach((key) => {
      if (key === "documento") return; // Skip validation for "documento"
      if (formData[key] === "" || formData[key] === null) {
        console.log("error en ", key);
        hasError = true;
      }
    });
    return hasError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.documento) {
      setError("Debes seleccionar un documento antes de enviar.");
      return;
    }

    const hasError = validateFields();
    if (hasError) {
      setError("Todos los campos son obligatorios.");
    } else {
      setError("");
      const [year, month, day] = formData.recibida.split('-');
      const [yearInc, monthInc, dayInc] = formData.fecha.split('-');

      const formDataPayload = new FormData();
      formDataPayload.append("idSolicitud", idSolicitud);
      formDataPayload.append("filedata", formData.documento);
      formDataPayload.append("fechaRecibido", `${day}-${month}-${year}`);
      formDataPayload.append("fechaInconformidad", `${dayInc}-${monthInc}-${yearInc}`);
      formDataPayload.append("razones", formData.area);

      const token = localStorage.getItem('accessToken');
      axios.post(API_URL_POST_INCONFORMARSE, formDataPayload, {
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          setIsVisible(true);
          console.log(response.data);
          fetchDatosInconformidad();
        })
        .catch((error) => {
          console.error("Error uploading form ", error);
        });
    }
  };

  const handleReset = () => {
    setFormData({
      documento: null,
      nombreDocumento: ""
    });
  };

  if (isLoadingAcademico) return <Loading />;
  if (isLoading) return <Loading />;

  const isReadOnly = datosInconformidad && datosInconformidad.razones;

  return (
    <>
      {datosInconformidad ? (
        <div className={styles.main}>
          <div>
            <p>{datosInconformidad.nombreAcademico}</p>
          </div>

          <div>
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.dateInput}>
                <div className={styles.fecha_ley}>
                  <p htmlFor="recibida">Solicitud recibida en</p>
                </div>
                <input
                  id="recibida"
                  name="recibida"
                  type="date"
                  placeholder="Seleccione una fecha"
                  value={formData.recibida}
                  onChange={handleChange}
                  readOnly={true} // Solo lectura si ya hay razones
                />
              </div>

              <div className={styles.dateInput}>
                <div className={styles.fecha_ley}>
                  <p htmlFor="fecha">Fecha inconformidad</p>
                </div>
                <input
                  id="fecha"
                  name="fecha"
                  type="date"
                  placeholder="Seleccione una fecha"
                  value={formData.fecha}
                  onChange={handleChange}
                  readOnly={true} // Solo lectura si ya hay razones
                />
              </div>

              <br />
              <p htmlFor="razones">Razones de la inconformidad</p>
              <textarea
                id="area"
                name="area"
                className={styles.txtArea}
                value={formData.area}
                onChange={handleChange}
                readOnly={isReadOnly} // Solo lectura si ya hay razones
              />

              <div className={styles.dateInput}>
                <p className={styles.info}>Documento que sustenta la inconformidad</p>
                {!isReadOnly && (
                  <label className={styles.labelFile} htmlFor="documento">
                    Examinar
                  </label>
                )}
                <input
                  id="documento"
                  className={styles.file}
                  type="file"
                  onChange={handleFileChange}
                  disabled={isReadOnly} // Deshabilitar si ya hay razones
                />
                <input
                  id="nombreDocumento"
                  name="nombreDocumento"
                  type="text"
                  value={formData.nombreDocumento}
                  readOnly
                />
              </div>

              {/* Si no hay razones, se muestran los botones */}
              {!isReadOnly && (
                <div className={styles.agregar}>
                  <button type="button" className={styles.btn} onClick={handleReset}>
                    Limpiar solicitud
                  </button>
                  <button type="submit" className={styles.btn}>
                    Registrar inconformidad
                  </button>
                </div>
              )}
            </form>

            {isReadOnly && (
              <Alert typeAlert={"success"}>
                <p>Tu inconformidad ha sido generada. No se pueden hacer más cambios.</p>
              </Alert>
            )}

            {isVisible && (
              <Alert typeAlert={"success"}>
                <p>Se ha guardado con éxito</p>
              </Alert>
            )}

            {error && <p className={styles.error}>{error}</p>}
          </div>
        </div>
      ) : (
        <p>No hay información para mostrar</p>
      )}
    </>
  );
};
