import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Alert from "../../../reutilizable/Alert";
import Loading from "../../../reutilizable/Loading";
import { useFormCriterios } from "../hooks/useFormCriterios";
import ProcesarCriterios from "./ProcesarCriterios";
import styles from "./styles/FormCriterios.module.css";
import TablaResultadosEvaluacion from "./TablaResultadosEvaluacion";
import { Spin } from "antd";

const FormCriterios = () => {
  const [codigoUsuario, setCodigoUsuario] = useState(null);
  

  const username = localStorage.getItem("userName" || "");

  useEffect(() => {
    if (username) setCodigoUsuario(username);
  }, [username]);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const { miembros, arbolCriterios, resultadosEvaluacion, nivel, puntaje, isLoading, mensaje, onSubmitEvaluar } =
    useFormCriterios();

  // Preselect the member when codigoUsuario is available
  const currentUser = miembros?.find((miembro) => miembro.codigo.toString() === codigoUsuario);
  useEffect(() => {
    if (codigoUsuario) {
      setValue("idMiembro", currentUser?.id);
    }
  }, [currentUser, setValue, codigoUsuario]);

  const handleFormSubmit = async (data) => {
    await onSubmitEvaluar(data);
    reset(); // limpiar despues de submit
  };

  if (isLoading) {
    return <Loading />;
  }

  const selectedMiembro = watch("idMiembro");

  console.log("Lista de miembros: ", miembros);
  

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {mensaje && (
        <Alert typeAlert={mensaje.type}>
          <p>{mensaje.mensaje}</p>
        </Alert>
      )}
      <div className={styles.formEvaluacion}>
        <ProcesarCriterios
          criterios={arbolCriterios}
          puntaje={puntaje}
          register={register}
          watch={watch}
          setValue={setValue}
          pdfDraw={true}
          typeRadio={0}
        />
      </div>

      {/* Sección del resto del formulario */}
      <div className={styles.restForm}>
        {console.log("codigoUsuario ", codigoUsuario)}
        <div className={styles.containerInputsSelectRadio}>
          <div className={styles.inputSelect}>
            <select
              disabled
              name="miembro"
              id="miembro"
              {...register("idMiembro", {
                required: {
                  value: true,
                  message: "Seleccione un miembro",
                },
              })}
              value={selectedMiembro || ""}
              onChange={(e) => setValue("idMiembro", e.target.value)} // Actualizamos el id seleccionado
            >
              <option value="" disabled>
                -- Seleccione el nombre de la lista --
              </option>
              {miembros &&
                miembros.map((miembro) => (
                  <option key={miembro.id} value={miembro.id}>
                    {miembro.nombre}
                  </option>
                ))}
            </select>
            {errors.idMiembro && <Alert typeAlert="error">{errors.idMiembro.message}</Alert>}
          </div>
        </div>

        <div className={styles.submit}>
          <button type="submit" disabled={isLoading} className={isLoading ? styles.btnDisabled : ""}>
            {isLoading && <Spin style={{ marginRight: "10px" }} />}
            Evaluar
          </button>
          <TablaResultadosEvaluacion resultados={resultadosEvaluacion} nivel={nivel} />
        </div>
      </div>
    </form>
  );
};

export default FormCriterios;
