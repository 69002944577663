export const CABECERAS_TABLA = [
  { id: 1, labelCabecera: "input" },
  { id: 2, labelCabecera: "#" },
  { id: 3, labelCabecera: "Apellido(S) Nombre(s)" },
  { id: 4, labelCabecera: "Tipo" },
  { id: 5, labelCabecera: "Dependencia" },
  { id: 6, labelCabecera: "Nivel" },
  { id: 7, labelCabecera: "Calidad" },
  { id: 8, labelCabecera: "Pts." },
  { id: 9, labelCabecera: "Acciones" },
];

export const initialStatusResponse = {
  isLoading: false,
  isError: false,
  message: "",
};