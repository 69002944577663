import React, { useState } from "react";
import { useResultadoEvaluacionStore } from "../store/useResultadoEvaluacionStore";
import styles from "./styles/Search.module.css";

function SearchResultadoEvaluacion({
  onSearch,
  onGetSuggestions,
  placeholder = "Search...",
}) {
  const { updateFilteredList, } = useResultadoEvaluacionStore();
  const [query, setQuery] = useState("");

  const handleChange = (e) => {
    setQuery(e.target.value);
    console.log("Change");
  };

  const handleSearch = () => {
    console.log("Search...");
    console.log(query);
    updateFilteredList(query);
  };
  return (
    <div className={styles.searchContainer}>
      <input
        type="text"
        value={query}
        onChange={handleChange}
        className={styles.searchInput}
        placeholder={placeholder}
      />
      <span
        onClick={handleSearch}
        className={styles.searchButton}
        style={{ cursor: "pointer" }}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 512 512"
          // height="200px"
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
        </svg>
      </span>
    </div>
  );
}

export default SearchResultadoEvaluacion;
