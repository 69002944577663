import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import styles from './styles/RowTablaItemCriterio.module.css';
import { replaceBrWithNewline } from '../../../utils';

const RowTablaItemCriterio = ({
  item,
  openModal,
  uploadedDocs,
  setUrlPdf,
  idSolicitud,
  handleUploadSuccess,
  handleDeleteSuccess
}) => {

  const { criterio, nodo, subCriterios } = item;
  const { id: idExpediente, nombre, documentos, valorFijo } = criterio;

  const existeDocumento = nodo !== null || uploadedDocs.has(idExpediente);
  const sinNombreDocumento = !documentos || documentos === '';
  const sinPuntaje = valorFijo === null;

  return (
    <>
      <tr>
        <td>{idExpediente}</td>
        <td>{replaceBrWithNewline(nombre)}</td>
        <td>
          {sinNombreDocumento ? (
            <p>Sin registro de documento probatorio</p>
          ) : (
            replaceBrWithNewline(documentos)
          )}
        </td>
        <td>
          {sinPuntaje ? (
            <p>Sin puntaje registrado para este ítem</p>
          ) : (
            <p>{valorFijo}</p>
          )}
        </td>
        <td className={styles.tdEvidencia}>
          {existeDocumento ? (
            <div className={styles.containerBtnPDF}>
              <button
                type='button'
                className={styles.buttonPdf}
                title='Ver PDF'
                onClick={() => {
                  openModal();
                  setUrlPdf(nodo);
                }}
              >
                <FontAwesomeIcon icon={faFilePdf} color='red' />
              </button>
            </div>
          ) : (
            <p>N/A</p>
          )}
        </td>
      </tr>
    </>
  );
};

export default RowTablaItemCriterio;