import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useModalidadEvaluacion } from "../store/useModalidadEvaluacionStore";
import { setTipoDeParticipacion } from "../services/modalidadEvaluacionService";
import { ERROR_MESSAGES_GENERICS_API } from "../../../../utils/messagesFromAPI";
import styles from "./styles/ContentModalSeleccionarModalidad.module.css";

const ContentModalSeleccionarModalidad = ({ onClose, setStatus, articulo }) => {
  const { setTipoParticipacion } = useModalidadEvaluacion();
  const [mensaje, setMensaje] = useState({
    type: null,
    mensaje: null,
  });

  const handleSetTipoParticipacion = async () => {
    let idTipoParticipacion = 2;
    if (articulo === 29) {
      idTipoParticipacion = 1;
    }
    await setTipoDeParticipacion(idTipoParticipacion)
      .then((response) => {
        if (response.status === 200) {
          setMensaje({
            type: "success",
            mensaje: "El tipo de participación se ha establecido correctamente",
          });
        }

        setStatus(1);
      })
      .catch((error) => {
        if (error.response) {
          const message = ERROR_MESSAGES_GENERICS_API[error.response.status] || ERROR_MESSAGES_GENERICS_API.default;
          setMensaje({
            type: "error",
            mensaje: message,
          });
        }
        console.error("Error al establecer el tipo de participación", error);
      });
    return;
  };

  return (
    <div className={styles.container}>
      <FontAwesomeIcon icon={faWarning} color="red" size="7x" />
      {articulo === 29 ? (
        <p className={styles.textoAdvertencia}>
          Está seguro que desea participar por Artículo 29 Evaluación,
          <br /> una vez confirmado no se podrá cambiar de modalidad
        </p>
      ) : (
        <p className={styles.textoAdvertencia}>
          Si selecciona la modalidad por Art. 26, recuerde <span className={styles.br}></span>
          que el beneficio que obtenga estará <span className={styles.br}></span>
          condicionando a mantener la vigencia del <span className={styles.br}></span>
          reconocimiento de su perfil deseable PRODEP.
        </p>
      )}

      <div className={styles.containerButtons}>
        <button
          className={styles.buttonAceptar}
          type="button"
          onClick={() => {
            handleSetTipoParticipacion();
            onClose();
          }}
        >
          Continuar
        </button>

        <button className={styles.cancelarButton} type="button" onClick={onClose}>
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default ContentModalSeleccionarModalidad;
