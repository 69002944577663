import React, { useEffect, useState } from "react";
import { useDatosInconformidad } from '../hooks/useDatosInconformidad';
import Alert from '../../../../../reutilizable/Alert';
import Loading from '../../../../../reutilizable/Loading';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useEvaluationStore } from "../../../../../store/useEvaluationStore";
import styles from "../styles/DatosInconformidad.module.css";

const DatosInconformidad = () => {

  const { selectedDataAcademico } = useEvaluationStore();
  const [mensajeAlerta, setMensajeAlerta] = useState({
    type: null,
    mensaje: null,
  });

  const {
    id: idSolicitud,
    codigo,
    inconformidad,
  } = selectedDataAcademico;

  const { formData, isLoadingAcademico, error } = useDatosInconformidad(codigo);
  const {
    recibida,
    fecha,
    area,
    nombreDocumento,
  } = formData;

  console.log(formData)

  if (isLoadingAcademico) {
    return <Loading />;
  }

  if (error) {
    return (
      <Alert typeAlert="error">
        <p>{error}</p>
      </Alert>
    );
  }
  return (
    <div className={styles.container}>
      {console.log("selectedDataAcademico ", selectedDataAcademico)}
      <div className={styles.containerData}>
        {/* DATOS PERSONALES */}
        {!inconformidad ? (
          <Alert typeAlert="warning">
            <p>No tiene una inconformidad el usuario</p>
          </Alert>
        ) : (
          <div className={styles.datosPersonales}>
            <p className={styles.nombreDatos}>
              <FontAwesomeIcon icon={faAngleRight} color={"yellow"} />{" "}
              Datos de Inconformidad
            </p>

            <div className={styles.containerTableData}>
              <div className={styles.inputContainer}>
                <label htmlFor="fechaRecibido">Solicitud recibida en</label>
                <input
                  id="fechaRecibido"
                  type="text"
                  value={recibida || "Sin registro"}
                  readOnly
                />
              </div>
              <div className={styles.inputContainer}>
                <label htmlFor="fechaInconformidad">Fecha inconformidad</label>
                <input
                  id="fechaInconformidad"
                  type="text"
                  value={fecha || "Sin registro"}
                  readOnly
                />
              </div>
              <div className={styles.inputContainer}>
                <label htmlFor="razonInconformidad">
                  Razones de la inconformidad
                </label>
                <textarea
                  id="razonInconformidad"
                  value={area || "Sin registro"}
                  readOnly
                />
              </div>

            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default DatosInconformidad;
