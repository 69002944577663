import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { useEvaluationStore } from "../../../store/useEvaluationStore";
import { obtenerRequisitosCotejo, changeRequisito } from "../services/cotejoRequisitosService";
import ViewerPDF from "../../../reutilizable/ViewerPDF";
import Loading from "../../../reutilizable/Loading";
import Modal from "../../../reutilizable/Modal";
import Table from "../../../reutilizable/Table";
import Alert from "../../../reutilizable/Alert";
import { ERROR_MESSAGES_GENERICS_API } from "../../../utils/messagesFromAPI";
import styles from "./styles/CotejoRequisitosPage.module.css";
import useCotejoStore from "../../../store/useCotejoStore";
import CargarEvidencia from "../../academico/expediente/components/CargarEvidencia";

const CotejoRequisitosPage = () => {
  const { selectedDataAcademico } = useEvaluationStore();
  const { setEsCotejadoRequisitos, esCotejadoRequisitos } = useCotejoStore();

  const [urlPDF, setUrlPDF] = useState(null);
  const [cotejado, setCotejado] = useState(false);
  const [requisitos, setRequisitos] = useState([]);
  const [idSolicitud, setIdSolicitud] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState(new Set());
  const [mensajeCotejado, setMensajeCotejado] = useState({
    type: "success",
    mensaje: "Requisitos cotejados correctamente",
  });
  const [mensaje, setMensaje] = useState({
    type: null,
    mensaje: null,
  });
  let cabecerasTable = [
    { id: 1, labelCabecera: "Id" },
    { id: 2, labelCabecera: "Requisito" },
    { id: 3, labelCabecera: "Ver Evidencia" },
    { id: 5, labelCabecera: "Reemplazar Evidencia" },
    { id: 4, labelCabecera: "Cotejar" },
  ];

  if (!esCotejadoRequisitos) cabecerasTable = cabecerasTable.filter((cabecera) => cabecera.id !== 5);

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    if (!selectedDataAcademico) return;
    setIdSolicitud(selectedDataAcademico.id);
  }, [selectedDataAcademico]);

  useEffect(() => {
    setMensaje(null);
    if (!idSolicitud) return;
    const idSolicitudString = idSolicitud.toString();
    obtenerRequisitosCotejo(idSolicitudString)
      .then((response) => {
        const requisitosResponse = response.data.requisitos;
        const cotejado = response.data.statusCotejo;

        if (requisitosResponse.length <= 0) {
          setMensaje({
            type: "warning",
            mensaje: "No hay requisitos para cotejar",
          });
          return;
        }

        cotejado === 1 ? setEsCotejadoRequisitos(true) : setEsCotejadoRequisitos(false);
        setRequisitos(requisitosResponse);
      })
      .catch((error) => {
        console.error("Error al obtener requisitos para cotejo", error);
        if (error.response) {
          const message = ERROR_MESSAGES_GENERICS_API[error.response.status] || ERROR_MESSAGES_GENERICS_API.default;
          setMensaje({
            mensaje: message,
            type: "error",
          });
        }
      });
    // eslint-disable-next-line
  }, [idSolicitud, cotejado, uploadedDocs]);
  console.log("🚀 ~ useEffect ~ idSolicitud:", idSolicitud);
  console.log("🚀 ~ useEffect ~ idSolicitud:", idSolicitud);

  const handleChecks = async (event) => {
    const { id } = event.target;
    const body = { idSolicitud, idItem: +id };

    await changeRequisito(body)
      .then(() => {
        console.log("Cambio exitoso");
      })
      .catch((error) => {
        console.error("Error al cambiar status de requisito para cotejo", error);
        if (error.response) {
          const message = ERROR_MESSAGES_GENERICS_API[error.response.status] || ERROR_MESSAGES_GENERICS_API.default;
          setMensaje({
            mensaje: message,
            type: "error",
          });
        }
      });
  };

  const openModal = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(!isModalOpen);

  if (mensaje) {
    return (
      <Alert typeAlert={mensaje.type}>
        <p>{mensaje.mensaje}</p>
      </Alert>
    );
  }

  const handleUploadSuccess = (idExpediente) => {
    setUploadedDocs((prev) => {
      const newSet = new Set(prev);
      newSet.add(idExpediente);
      return newSet;
    });
  };

  return (
    <div className={styles.containerCotejoRequisitos}>
      <div className={styles.containerTablaRequisitos}>
        <Modal isOpen={isModalOpen} onClose={closeModal} width="750px">
          <ViewerPDF urlPdf={urlPDF} />
        </Modal>

        <Table cabecerasTable={cabecerasTable}>
          {requisitos?.length ? (
            requisitos?.map((requisito) => {
              return (
                <tr key={requisito.id}>
                  <td>{requisito.romano}</td>
                  <td>
                    <label htmlFor={requisito.id} className={styles.label}>
                      {requisito.nombre}
                    </label>
                  </td>
                  <td className={styles.tdTablaCotejoRequisitos}>
                    <div className={styles.containerBtnPDF}>
                      <button
                        type="button"
                        className={styles.buttonPdf}
                        title="Ver PDF"
                        onClick={() => {
                          openModal();
                          setUrlPDF(requisito.nodo || "");
                        }}
                      >
                        <FontAwesomeIcon icon={faFilePdf} color="green" />
                      </button>
                    </div>
                  </td>
                  {esCotejadoRequisitos ? (
                    <td>
                      <CargarEvidencia
                        idExpediente={requisito.id}
                        idSolicitud={idSolicitud}
                        tipoUpload="requisito"
                        onUploadSuccess={() => handleUploadSuccess(requisito.id)}
                      />
                    </td>
                  ) : (
                    <></>
                  )}
                  <td className={styles.tdCheckCotejo}>
                    <input
                      type="checkbox"
                      className={`checkbox_green ${styles.checkCotejo}`}
                      id={requisito.id}
                      {...register(`${requisito.id}`)}
                      onChange={handleChecks}
                      defaultChecked={requisito.cotejado}
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <Loading />
          )}
        </Table>

        {cotejado && mensajeCotejado && (
          <Alert typeAlert={mensajeCotejado.type}>
            <p>{mensajeCotejado.mensaje}</p>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default CotejoRequisitosPage;
