import axios from "axios";
import React, { useEffect, useState } from "react";
import { Menu, useMenu } from "../../../reutilizable/Menu";
import ActivarInactivaSolicitud from "../../administradorConvocatoria/activarInactivarSolicitud/ActivarInactivarSolicitud";
import { Footer, Header } from "../../layout";
import ActualizarIntegranteComision from "../ActualizarIntegrantesComision/ActualizarIntegranteComision";
import BloquearDesbloquearAcademico from "../bloquearDesbloquearAcademico/bloquearDesbloquearAcademico";
import { Evaluacion } from "../components/Evaluacion";
import Expediente from "../expediente/Expediente";
import GenerarReporte from "../GenerarReportes/page/GenerarReportePage";
import Inconformidades from "../Inconformidades/pages/InconformidadesPage";
import IncumplimientosAcademico from "../IncumplimientosAcademico/IncumplimientosAcademico";
import { usePublicacionFinal } from "../publicacion-resultados/publicacion-final/hooks/usePublicacionFinal";
import { SuperPase } from "../superPase/SuperPase";
import styles from "./styles/AdministradorConvocatoriaPage.module.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_URL_SUBMENUS = `${API_BASE_URL}/api/v1/actividad/admin_convocatoria`;

export const AdministradorConvocatoriaPage = () => {
  const { fetchPublicacionFinal, isLoading, error } = usePublicacionFinal();

  const [menuResponse, setMenuResponse] = useState([]);

  const handlePublicacionFinal = async () => {
    try {
      await fetchPublicacionFinal();
    } catch (err) {
      alert("Error al descargar el archivo.");
    }
  };

  const componentMap = {
    "Publicación de los resultados": handlePublicacionFinal, 
    "Administración": <p>componente</p>, 
    "Super pase": <SuperPase />, 
    "Incumplimientos de académico": <IncumplimientosAcademico />, 
    "Solicitudes": <ActivarInactivaSolicitud />, 
    "Ver expediente": <Expediente />, 
    "Ver evaluaciones": <Evaluacion />, 
    "Actualizar integrantes de comisión": <ActualizarIntegranteComision />,
    "Generar reportes estadísticos": <GenerarReporte />, 
    "Inconformidades": <Inconformidades />, 
    "Bloquear/desbloquear académicos": <BloquearDesbloquearAcademico />, 
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(API_URL_SUBMENUS, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        console.log("response.data.menus ", response.data.menus)
        const sortedMenus = (response.data.menus || [])
          .map((menu) => ({
            ...menu,
            submenus: Array.isArray(menu.submenus)
              ? menu.submenus
                  .map((submenu) => ({
                    ...submenu,
                  }))
                  .sort((a, b) => (a.orden === b.orden ? a.id - b.id : a.orden - b.orden))
              : [],
          }))
          .sort((a, b) => (a.orden === b.orden ? a.id - b.id : a.orden - b.orden));

        setMenuResponse(sortedMenus);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenuData();
  }, []);

  const menu = useMenu(
    menuResponse.map((menu) => ({
      label: menu.nombreCorto,
      ...(menu.submenus.length > 0
        ? {
            children: menu.submenus.map((submenu) => ({
              label: submenu.nombreCorto,
              element:
                typeof componentMap[submenu.nombreCorto] === "function"
                  ? null
                  : componentMap[submenu.nombreCorto] || <p>{submenu.nombreCorto}</p>,
              onClick:
                typeof componentMap[submenu.nombreCorto] === "function"
                  ? componentMap[submenu.nombreCorto]
                  : null,
            })),
          }
        : {
            element:
              typeof componentMap[menu.nombreCorto] === "function"
                ? null
                : componentMap[menu.nombreCorto] || <p>{menu.nombreCorto}</p>,
            onClick:
              typeof componentMap[menu.nombreCorto] === "function"
                ? componentMap[menu.nombreCorto]
                : null,
          }),
    })),
    { selectedValue: "Instrucciones", isVertical: false }
  );

  return (
    <div className={styles.jefe_departamento_page_container}>
      <Header />
      <Menu menu={menu} />
      {menu.element}
      <Footer />
    </div>
  );
};
