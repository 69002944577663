import { useEffect, useState } from "react";
import { getDataAcademic, updateSuperPase } from "../service";

export const useSuperPase = () => {
  const [codigo, setCodigo] = useState("");
  const [error, setError] = useState("");
  const [academico, setAcademico] = useState(null);
  const [superPase, setSuperPase] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmitCodigo = async (event) => {
    event.preventDefault();
    setAcademico(null);
    setError("");
    setLoading(true);
    setSuperPase(false);

    try {
      if (!codigo.trim()) throw new Error("Campo obligatorio");

      const response = await getDataAcademic(codigo);
      setAcademico(response);
      setSuperPase(response.superpase);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    try {
      await updateSuperPase(codigo, superPase);
      setIsVisible(!isVisible);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isVisible]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [error]);

  const onChange = (e) => {
    if (!e.target.name) {
      setCodigo(e.target.value);
    }

    if (e.target.name === "superPaseSi") setSuperPase(true);
    if (e.target.name === "superPaseNo") setSuperPase(false);
  };

  return {
    // properties
    codigo,
    error,
    academico,
    superPase,
    isVisible,
    loading,
    // methods
    handleSubmitCodigo,
    handleFormSubmit,
    onChange,
  };
};
