import React, { useEffect, useState } from 'react';
import Alert from '../../../../reutilizable/Alert';
import ViewerPDF from '../../../../reutilizable/ViewerPDF';
import { tiposDocumentos } from '../services';
import { useEvaluationStore } from '../../../../store/useEvaluationStore';
import { ERROR_MESSAGES_GENERICS_API } from '../../../../utils/messagesFromAPI';
import styles from '../styles/SolicitudPage.module.css';

const SolicitudPage = () => {

  const { selectedDataAcademico } = useEvaluationStore();
  console.log(selectedDataAcademico);

  const [urlPdfSolicitud, setuUrlPdfSolicitud] = useState(null);
  const [mensajeAlerta, setMensajeAlerta] = useState({
    tipo: null,
    mensaje: null
  });
  const [tipoDocumento, setTipoDocumento] = useState({
    id: null,
    mandatorio: true,
    nombre: '',
    orden: null,
    sigla: "K",
    idConvocatoria: null
  });
  
  useEffect(() => {
    setMensajeAlerta(null);

    tiposDocumentos()
     .then(response => {
        if (response) {
          console.log(response);
          // Buscamos en el arreglo los datos referentes al documento de solicitud
          const nombreDocumento = response.find(item => item.nombre === 'Solicitud');
          console.log(`Document name: `, nombreDocumento);
          setTipoDocumento(nombreDocumento);
        }
      })
      .catch(error => {
        if (error.response) {
          const message = ERROR_MESSAGES_GENERICS_API[error.response.status] || ERROR_MESSAGES_GENERICS_API.default;
          setMensajeAlerta({
            mensaje: message,
            type: 'error'
          });
        }
      });
  }, []);

  useEffect(() => {
    setMensajeAlerta(null);
    console.log('New Document name: ', tipoDocumento);
    const arregloArchivos = selectedDataAcademico?.archivos;
    console.log(arregloArchivos);
    const SIGLA = tipoDocumento.sigla;
    console.log(SIGLA);
    // La sigla hace referencia al tipo de archivo que es
    const result = arregloArchivos.find(item => item.sigla === "K");
    console.log(result);

    if (!result) {
      setMensajeAlerta({
        tipo: 'warning',
        mensaje: 'No se ha encontrado el archivo de solicitud'
      });
      return;
    }

    setuUrlPdfSolicitud(result);
    // eslint-disable-next-line
  }, [selectedDataAcademico, tipoDocumento.sigla]);

  if (mensajeAlerta) {
    return (
      <Alert typeAlert={mensajeAlerta.tipo}>
        <p>{mensajeAlerta.mensaje}</p>
      </Alert>
    );
  }

  return (
    <div className={styles.containerPdf}>
      <ViewerPDF urlPdf={urlPdfSolicitud?.nodoId}/>
    </div>
  )
}

export default SolicitudPage