import React from 'react';
import { useEvaluationStore } from '../../../../store/useEvaluationStore';
import { Menu, useMenu } from '../../../../reutilizable/Menu';
import AsideSecretaria from '../../components/AsideSecretaria';
import VerExpedienteRequisitos from './VerExpedienteRequisitos';
import styles from '../styles/VerExpedientePage.module.css';
import VerExpedienteCriterios from './VerExpedienteCriterios';
import { DatosParticipante } from '../../components/DatosParticipante';

const VerExpedientePage = () => {
  const { selectedDataAcademico } = useEvaluationStore();

  const menu = useMenu(
    [
      {
        label: 'Datos participante',
        element: <DatosParticipante />,
      },
      {
        label: 'Requisitos',
        element: <VerExpedienteRequisitos/>
      },
      {
        label: 'Rubros de evaluación',
        element: <VerExpedienteCriterios/>
      },
    ],
    {
      customClass: {
        menu: styles.menuContainer,
        menuOption: styles.menuOption,
        subMenuOption: styles.subMenuOption,
        selected: styles.selected,
      },
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.containerAside}>
        <AsideSecretaria />
      </div>

      <div className={styles.containerContent}>
        <div className={styles.containerMenu}>
          {selectedDataAcademico?.nombre && (
            <div className={styles.nombreAcademico}>
              <p>{`${selectedDataAcademico.nombre} ${selectedDataAcademico.apellidoPaterno} ${selectedDataAcademico.apellidoMaterno}`}</p>
            </div>
          )}

          <div className={styles.menu}>
            <Menu menu={menu} />
            <div className={styles.optionMenu}>
              {menu.element}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerExpedientePage;