import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import Modal from "../../../reutilizable/Modal";
import ViewerPDF from "../../../reutilizable/ViewerPDF";
import styles from "./styles/ProcesarCriterios.module.css";

const Encabezado = ({ nivel, children }) => {
  const Tag = `h${nivel + 2}`;
  const dynamicClassName = `${styles.encabezado} ${styles[`encabezadoNivel${nivel}`]}`;

  return <Tag className={dynamicClassName}>{children}</Tag>;
};

const ProcesarCriterios = ({ criterios = [], puntaje = {}, register, watch, setValue, pdfDraw, typeRadio }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let pdfFlag = false;

  const [urlPDF, setUrlPDF] = useState(null);
  if (!criterios || criterios.length === 0) return null;

  const openModal = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(!isModalOpen);

  let prevSelected = 0;

  const radioSelected = (e) => {
    const { id, value } = e.target;

    setValue(`${id}`, value);
    if (prevSelected !== 0) setValue(`${prevSelected}`, "0");

    prevSelected = id;
  };

  const checked = (id, puntaje) => {
    if (puntaje) {
      prevSelected = id;
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div>
        <Modal isOpen={isModalOpen} onClose={closeModal} width="850px">
          <ViewerPDF urlPdf={urlPDF} />
        </Modal>

        {criterios?.map((nodo, index) => {
          const { criterio, subCriterios = [], nivel, nodo: urlPdf, tipo } = nodo;
          const { id, nombre, topeVeces } = criterio;
          const existeDocumento = urlPdf === null;

          // if (nivel === 2 && subCriterios.length === 0) {
          //   return (
          //     <div key={id} className={styles.formPregunta} style={{ marginLeft: "10px" }}>
          //       <Encabezado nivel={nivel} criterio={criterio}>
          //         {nombre}
          //       </Encabezado>

          //       <ProcesarCriterios
          //         criterios={subCriterios}
          //         ancestroTopeVeces={topeVeces}
          //         puntaje={puntaje}
          //         register={register}
          //         watch={watch}
          //         setValue={setValue}
          //         pdfDraw={true}
          //       />
          //     </div>
          //   );
          // }

          if (subCriterios.length === 0 || !subCriterios) {
            return (
              <div key={`${id}${index}`} className={styles.containerRespuestas}>
                <div className={styles.respuesta}>
                  {typeRadio ? (
                    <>
                      <input
                        type="radio"
                        name="radio"
                        id={id}
                        value={1}
                        className={styles.inputRadio}
                        {...register(`${id}`)}
                        onChange={radioSelected}
                        defaultChecked={checked(id, puntaje[id])}
                      />
                      <label className={styles.respuestaLabel} htmlFor={id}>
                        {nombre}
                      </label>
                    </>
                  ) : (
                    <>
                      <input
                        type="text"
                        name={id}
                        id={id}
                        className={styles.inputText}
                        readOnly={existeDocumento ? true : false}
                        // value={puntaje[id] || '0'}
                        //value={watch(`criterio-${id}`, puntaje[id] || '0')}
                        defaultValue={puntaje[id] || "0"}
                        {...register(`${id}`)}
                        onChange={(e) => setValue(`${id}`, e.target.value)}
                      />
                      <label className={styles.respuestaLabel} htmlFor={id}>
                        {nombre}
                      </label>
                    </>
                  )}

                  {pdfDraw && (
                    <button
                      type="button"
                      className={`${styles.buttonPdf}`}
                      title={existeDocumento ? "Sin documento" : "Ver Documento"}
                      disabled={existeDocumento ? true : false}
                      onClick={() => {
                        openModal();
                        setUrlPDF(urlPdf);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color={existeDocumento ? "gray" : "red"}
                        size="2xl"
                        cursor={existeDocumento ? "not-allowed" : "pointer"}
                      />
                    </button>
                  )}
                </div>
              </div>
            );
          } else {
            if (index === 0 && nivel === 0) {
              pdfFlag = true;
            } else if (index === 1 && nivel === 0) {
              pdfFlag = false;
            } else if (index === 2 && nivel === 0) {
              pdfFlag = false;
            } else {
              pdfFlag = pdfDraw;
            }

            return (
              <div key={id} className={styles.formPregunta} style={{ marginLeft: "10px" }}>
                <Encabezado nivel={nivel} criterio={criterio}>
                  {nombre}
                </Encabezado>

                <ProcesarCriterios
                  criterios={subCriterios}
                  ancestroTopeVeces={topeVeces}
                  puntaje={puntaje}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  pdfDraw={pdfFlag}
                  typeRadio={tipo}
                />
              </div>
            );
          }

          //return null; // Por seguridad
        })}
      </div>
    </>
  );
};

export default ProcesarCriterios;
