// External libraries.
import { useState } from "react";

// Internal libraries.
import { ERROR_MESSAGES_GENERICS_API } from "../../../../utils/messagesFromAPI";
import { enviarSolicitud } from "./../services/Solicitud";
import Loading from "../../../../reutilizable/Loading";
import Alert from "../../../../reutilizable/Alert";

// Styles.
import styles from "./EnviarSolicitud.module.css";
import { useEvaluationStore } from "../../../../store/useEvaluationStore";
import { useDatosAcademico } from "../../store/useDatosAcademico";

const EnviarSolicitud = () => {
  const [mensajeAlerta, setMensajeAlerta] = useState({ type: null, mensaje: null });
  const [errorSubmit, setErrorSubmit] = useState({ type: null, mensaje: null });
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { idSolicitud } = useEvaluationStore();
  const { datosAcademico } = useDatosAcademico();
  const { idSolicitud } = datosAcademico;

  const handleSend = async () => {
    console.log("handleSend!");

    const body = { idSolicitud };
    console.log(body);

    setLoading(true);

    await enviarSolicitud(body)
      .then((response) => {
        if (response.status === 200) {
          setIsVisible(!isVisible);
          setMensajeAlerta({
            type: "success",
            mensaje: "Solicitud enviada correctamente",
          });
        }
        
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error al enviar solicitud: ", error);
        if (error.response) {
          if(error.response.status.toString() === "404"){
            const message = error.response?.data?.mensaje||'Revisa tu información';
            setErrorSubmit({
              type: "error",
              mensaje: message,
            });
          }else{
            const message = error.response?.data?.mensaje||'Revisa tu información';
            setErrorSubmit({
              type: "error",
              mensaje: message,
            });
          }
      
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className={styles.solicitudContainer}>
        <h2 className={styles.titulo}>ENVIO DE SOLICITUD</h2>
        <p>
          Por favor revise que la información de su solicitud sea correcta antes de enviar, este proceso no puede
          deshacerse.
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <button className={`${styles.btn} ${styles.enviar}`} onClick={handleSend}>
          Enviar solicitud
        </button>
      </div>
      <div>
        {loading && <Loading />}
        {isVisible && (
          <Alert typeAlert={mensajeAlerta.type}>
            <p>{mensajeAlerta.mensaje}</p>
          </Alert>
        )}
        {errorSubmit && (
          <Alert typeAlert={errorSubmit.type}>
            <p>{errorSubmit.mensaje}</p>
          </Alert>
        )}
      </div>
    </>
  );
};

export default EnviarSolicitud;
