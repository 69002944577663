import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  getResultadoEvaluacionList,
  postResultadoEvaluacion,
} from "../features/resultadoEvaluacion";
export const useResultadoEvaluacionStore = create(
  persist(
    (set, get) => ({
      resultadoEvaluacionList: null,
      isErrorResultadoEvaluacionList: null,
      isLoading: true,

      fetchResultadoEvaluacion: async () => {
        try {
          const response = await getResultadoEvaluacionList();
          set({
            resultadoEvaluacionList: response,
            isLoading: false,
            isErrorResultadoEvaluacionList: null,
          });
        } catch (error) {
          set({
            isErrorResultadoEvaluacionList: error,
          });
        }
      },
      generatePdf: async (body) => {
        try {
          const response = await postResultadoEvaluacion(body);
          return response;
        } catch (error) {
          console.log(error);
        }
      },

      updateFilteredList: async (query) => {
        try {
          const evalList = await getResultadoEvaluacionList();

          if (!Array.isArray(evalList) && evalList.length <= 0) {
            return;
          }

          const filteredList = evalList.filter((item) =>
            Object.values(item).some((value) =>
              value.toString().toLowerCase().includes(query.toLowerCase())
            )
          );

          set({
            resultadoEvaluacionList: filteredList,
            isErrorResultadoEvaluacionList: null,
            isLoading: false,
          });

          if (filteredList.length === 0) {
            console.log("No se encontraron resultados.");
          }
        } catch (error) {
          console.error("Error al filtrar resultados:", error);
          set({
            isErrorResultadoEvaluacionList: error.message,
            isLoading: false,
          });
        }
      },

      selectOptionFilteredList: async (option) => {
        try {
          const evalList = await getResultadoEvaluacionList();

          if (!Array.isArray(evalList) && evalList.length <= 0) {
            return;
          }

          const filteredList = evalList.filter(
            (item) =>
              item.nombreDependencia.toLowerCase() === option.dependencia.toLowerCase() ||
              item.id_antecesor === option.id
          );

          console.log(evalList);
          console.log(filteredList);

          set({
            resultadoEvaluacionList: filteredList,
            isErrorResultadoEvaluacionList: null,
            isLoading: false,
          });

          if (filteredList.length === 0) {
            console.log("No se encontraron resultados.");
          }
        } catch (error) {
          console.error("Error al seleccionar opción:", error);
          set({
            isErrorResultadoEvaluacionList: error.message,
            isLoading: false,
          });
        }
      },
    }),
    { name: "data-resultado-evaluacion" }
  )
);
